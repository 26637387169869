import { useRelativeLink } from '@commerce/product/use-relative-link'
import cn from 'classnames'
import { useState, useRef, useEffect } from 'react'
import Icon from '@components/icons'

import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

const Index = ({
  data,
  pageHandle,
  theme = '',
  cns = {},
  hideLink,
  playAni = true,
  gtmRef,
}) => {
  const { setRelativeLink } = useRelativeLink()

  if (!data.preTitle && !data?.title) return null

  return (
    <div
      className={cn(
        'flex items-end justify-between pb-[32px] xl:pb-[24px]',
        theme === 'dark' ? 'text-[#fff]' : 'text-[#1D1D1F]',
        cns?.txt,
        data?.textAlign === 'center' && 'text-center'
      )}
    >
      <div
        className={cn(
          cns?.txtBox,
          data?.styles?.textFullWidth ? 'w-full' : 'w-[50%] md:w-full'
        )}
      >
        {data?.preTitle && (
          <div className="mb-[8px] text-[20px] font-[600] leading-[1.4] md:text-[12px] xl:text-[14px]">
            <HomeTxt txt={data?.preTitle} playAni={playAni} />
          </div>
        )}
        {data?.title && (
          <h2 className="title text-[40px] font-[700] leading-[1.2] l:!text-[26px] xl:text-[32px] [&_img]:inline-block">
            <HomeTxt txt={data?.title} playAni={playAni} />
          </h2>
        )}
        {data?.desc && (
          <div className="mt-[4px] text-[20px] font-[500] leading-[1.4] xl:text-[16px]">
            <HomeTxt txt={data?.desc} playAni={playAni} />
          </div>
        )}
      </div>
      {data?.boxLinkUrl && !hideLink && (
        <a
          className="boxLink flex items-center pb-[10px] text-[16px] font-[600] leading-[1.4] text-[#777] transition-all duration-[0.4s] md:hidden [&:hover]:text-[#1D1D1F] [&:hover_path]:stroke-[#1D1D1F]"
          href={`${setRelativeLink({ link: data?.boxLinkUrl })}?ref=${
            gtmRef || pageHandle
          }`}
        >
          <span dangerouslySetInnerHTML={{ __html: data?.boxLinkTxt }} />
          <Icon
            iconKey="arrowRight"
            width={24}
            height={24}
            className="mb-[3px]"
          ></Icon>
        </a>
      )}
    </div>
  )
}

export default Index

const HomeTxt = ({ txt, playAni }) => {
  const ref = useRef(null)
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.create({
      trigger: ref?.current,
      start: 'bottom bottom',
      end: '+=0',
      // markers: true,
      onEnter: () => {
        setIsInView(true)
      },
    })
  }, [])

  return (
    <span
      ref={ref}
      style={{
        clipPath:
          isInView && playAni
            ? 'rect(-10% 150% 500% 0%)'
            : 'rect(-10% 0% 500% 0%)',
        transition: 'all 0.6s',
      }}
      dangerouslySetInnerHTML={{ __html: txt }}
    />
  )
}
