import cn from 'classnames'
import s from './index.module.css'
import { Text, Picture } from '@components/ui'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Index = ({ id, data }) => {
  const { setRelativeLink } = useRelativeLink()
  if (!data) return null
  return (
    <section
      className={cn('layer', {
        ['pt-[80px] x:pt-[60px] ']: data?.paddingTop,
        ['pb-[80px] x:pb-[60px]']: data?.paddingBottom,
      })}
      id={id}
      style={data?.styles}
    >
      <div className="content">
        {data?.title && (
          <Text
            className={cn(s.title, 'text-[#000]')}
            html={data?.title}
            variant="sectionHeading"
          />
        )}

        {data?.list && (
          <ul className={cn(s.col, { [s.colLength]: data?.list.length <= 6 })}>
            {data?.list?.map((item, index) => {
              const link =
                setRelativeLink({ link: item?.to }) +
                (data?.ref && `?ref=${data?.ref}`)
              return (
                <li key={index}>
                  <a
                    className={cn(s.jump, { [s.rowImageWrap]: item?.img })}
                    href={link}
                  >
                    {item?.img && (
                      <div className={s.rowImageBox}>
                        <Picture
                          className={s.rowImage}
                          source={`${item?.img}, 769`}
                        />
                      </div>
                    )}

                    {item?.title && <p className={s.category}>{item?.title}</p>}
                    {item?.subtitle && (
                      <p className={s.subtitle}>{item?.subtitle}</p>
                    )}
                  </a>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </section>
  )
}

export default Index
