import cn from 'classnames'
import s from './index.module.css'

import { Navigation } from 'swiper/modules'
import { Text, Picture } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'

const Index = ({ id, data }) => {
  const settings = {
    speed: 400,
    slidesPerView: 4,
    spaceBetween: 13,
    centerMode: true,
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 4,
      },
      1280: {
        slidesPerView: 4,
      },
    },
    slideToClickedSlide: true,
    modules: [Navigation],
    navigation: {
      nextEl: `.${s.button_next}`,
      prevEl: `.${s.button_pre}`,
    },
  }

  return (
    <section
      id={id}
      style={data?.styles}
      className={cn('layer', s.convenientWrap, {
        ['pb-[80px] l:pb-[60px]']: data?.paddingBottom,
      })}
    >
      <div className="content">
        {data?.title && (
          <Text
            className={s.title}
            html={data?.title}
            variant="sectionHeading"
          />
        )}

        {data?.subtitle && (
          <Text
            className={s.subtitle}
            html={data?.subtitle}
            variant="sectionHeading"
          />
        )}

        <div
          className={cn(s.swiperWrap, {
            [s.swiperLength]: data?.list?.length < 4,
          })}
        >
          <Swiper {...settings} className={s.swiperBox}>
            {data?.list?.map((item, index) => (
              <SwiperSlide key={index} className="!flex !h-[unset]">
                <div className={s.row}>
                  <div className="h-[48px] w-[48px]">
                    <Picture
                      source={`${item?.img}`}
                      className={cn(s.slickImg)}
                    />
                  </div>
                  {item?.title && (
                    <Text
                      className={s.itemTitle}
                      html={item?.title}
                      variant="paragraph"
                    />
                  )}

                  {item?.tag && (
                    <Text
                      className={s.tag}
                      html={item?.tag}
                      variant="paragraph"
                    />
                  )}
                  {item?.desc && (
                    <Text
                      className={s.desc}
                      html={item?.desc}
                      variant="paragraph"
                    />
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className={cn('swiper-button-next', s.button_next, {
              ['!flex md:!hidden']: data?.list?.length > 4,
            })}
          >
            <Picture
              source={
                'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Kit-Arrows.svg?v=1689149162'
              }
            />
          </div>

          <div
            className={cn('swiper-button-prev', s.button_pre, {
              ['!flex md:!hidden']: data?.list?.length > 4,
            })}
          >
            <Picture
              source={
                'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Kit-Arrows_1.svg?v=1689149162'
              }
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Index
