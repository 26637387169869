import { Picture } from '@components/ui'
import SectionTitle from '@components/layout/SectionTitle'
import styles from './SearchFans.module.css'
import cn from 'classnames'
import { motion } from 'framer-motion'
import Button from '@components/control/Button'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const SearchFans = ({ data }) => {
  let itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: {
      x: '-120%',
      transition: { duration: 0.3 },
    },
  }
  const { setRelativeLink } = useRelativeLink()
  const link = `${setRelativeLink({ link: data?.link })}?ref=homePageBanner`
  return (
    <div className={cn(styles.root)}>
      <SectionTitle
        subTitle={data?.allSubTitle}
        title={data?.allTitle}
        className={styles.title_box}
        cns={{
          subTitle: styles.subTitle,
        }}
        jumpOver={data?.jumpOver}
        blogLink={data?.blogLink}
      />
      <section
        className={cn(
          styles.searchFans_layer,
          'layer',
          `${data?.styles?.paddingBottom ? 'pb-[50px]' : ''}`
        )}
      >
        <motion.div layout className={cn(styles.searchFans_content, 'content')}>
          <motion.div
            onClick={() => {
              window.location.href = link
            }}
            layout
            className={cn(styles.searchFans_box, {
              [styles.searchFans_box_left]: !data?.imgLeft,
            })}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.6 }}
            variants={{
              show: {
                transition: {
                  duration: 0.5,
                  staggerChildren: 0.05,
                },
              },
              hidden: {
                transition: {
                  duration: 0.5,
                },
              },
            }}
          >
            <motion.div
              layout
              className={cn(styles.searchFans_flex, {
                [styles.searchFans_onlyOne_left]: !data?.imgLeft,
              })}
            >
              <motion.div layout className={cn(styles.searchFans_textAll)}>
                <motion.div className="md_layer_grid">
                  <motion.div className="md_content_grid">
                    <motion.div
                      layout
                      className={cn(styles.searchFans_title_box)}
                    >
                      <motion.p
                        layout
                        variants={itemVariants}
                        className={cn(styles.searchFans_subTitle)}
                        dangerouslySetInnerHTML={{
                          __html: data?.subTitle,
                        }}
                      />
                      {data?.title &&
                        data?.title.map((item, index) => (
                          <motion.p
                            layout
                            key={`searchFanstitle-${index}`}
                            variants={itemVariants}
                            className={cn(styles.searchFans_title)}
                            dangerouslySetInnerHTML={{
                              __html: item?.title,
                            }}
                          />
                        ))}
                    </motion.div>
                    <motion.div layout className={styles.searchFans_text_box}>
                      {data?.text &&
                        data?.text.map((itm, idx) => (
                          <motion.p
                            key={`searchFanstext-${idx}`}
                            variants={itemVariants}
                            layout
                            className={styles.searchFans_text}
                            dangerouslySetInnerHTML={{
                              __html: itm?.text,
                            }}
                          />
                        ))}
                    </motion.div>
                    <motion.p
                      variants={itemVariants}
                      layout
                      className={cn(styles.searchFans_a_box)}
                    >
                      {data?.isBtn ? (
                        <Button className="md:!max-w-[unset]">
                          <motion.a
                            layout
                            className={cn(
                              styles.searchFans_a,
                              styles.searchFansBtn
                            )}
                            href={link}
                            target="_self"
                            dangerouslySetInnerHTML={{
                              __html:
                                data?.learnMore +
                                `<span class="hidden">${data?.subTitle}</span>`,
                            }}
                          />
                        </Button>
                      ) : (
                        <motion.a
                          layout
                          className={styles.searchFans_a}
                          href={link}
                          target="_self"
                          dangerouslySetInnerHTML={{
                            __html:
                              data?.learnMore +
                              `<span class="hidden">${data?.subTitle}</span>`,
                          }}
                        />
                      )}
                    </motion.p>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div className="flex-1 cursor-pointer overflow-hidden">
                <Picture
                  source={`${data?.img},${data?.img_mob} 768`}
                  className={cn(
                    styles.searchFans_img,
                    'aspect-w-[1088]',
                    ' aspect-h-[500]',
                    'md:aspect-w-[768] ',
                    'md:aspect-h-[449]'
                  )}
                />
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </section>
    </div>
  )
}
export default SearchFans
