/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import Script from 'next/script'
import throttle from 'lodash.throttle'
import Slider from 'react-slick'
import { useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Text, Picture } from '@components/ui'
import { easingTransitions } from '@lib/utils/animate'
import { ArrowLeft, ArrowRight } from '@components/icons/next'
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion'

import SectionTitle from '@components/layout/SectionTitle'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import s from './Accordion.module.css'
import { XMarkIcon } from '@heroicons/react/24/outline'

const Accordion = ({ page, data, className, gs = {} }) => {
  const [dialogWrapShow, setDialogWrapShow] = useState(false)
  const [current, setCurrent] = useState(0)
  const [youTubeId, setYouTubeId] = useState('')
  const ref = useRef(null)
  const { scrollXProgress } = useScroll({
    container: ref,
  })
  const itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: {
      x: '-130%',
      transition: { duration: 0.3 },
    },
  }
  var players
  const onYouTubeIframeAPIReady = (id) => {
    if (YT && YT.Player) {
      players = new YT.Player('player', {
        height: '70%',
        width: '80%',
        videoId: id,
        events: {
          onReady: onPlayerReady,
        },
        // playerVars: {
        //   loop: 1,
        //   autoplay: 1,
        //   playlist: sections?.section_3?.videoUrl,
        // },
      })
    }
  }
  const onPlayerReady = (event) => {
    event.target.playVideo()
  }
  useEffect(() => {
    dialogWrapShow && onYouTubeIframeAPIReady(youTubeId)
  }, [youTubeId, dialogWrapShow])
  return (
    <div className={cn(s.root, className, gs?.accordionRoot)}>
      <Script
        id="youtube-iframe-api"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `var tag = document.createElement('script'); tag.src
                ='https://www.youtube.com/iframe_api'; var firstScriptTag =
                document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);`,
        }}
      />
      {dialogWrapShow && (
        <div
          className={cn(
            'fixed left-0 top-0 z-[9999] flex h-full w-full items-center justify-center ',
            s.youtubeBox
          )}
        >
          <div id="player"></div>
          <XMarkIcon
            onClick={() => {
              setDialogWrapShow(false)
            }}
            className="absolute right-[10%] top-[10%] w-[30px] cursor-pointer text-white"
          />
        </div>
      )}

      <div className="layer not-in-pad">
        <div className={cn('content', s.accordionBox)}>
          {data
            ?.map((item, i) => {
              return (
                i < 5 && (
                  <motion.div
                    key={`item-${item?.name}`}
                    className={s.accordionContent}
                    onHoverStart={() => setCurrent(i)}
                  >
                    <div className={s.imgBox}>
                      <Picture source={`${item?.img}`} alt="" />
                    </div>
                    <motion.div
                      className={cn(s.contentBox, gs?.contentBox)}
                      key={`contentBox-${i}`}
                      animate={current === i ? 'show' : 'hidden'}
                      transition={{
                        duration: 0.5,
                        ease: easingTransitions?.easeInOutQuart,
                      }}
                      variants={{
                        hidden: {
                          width: 0,
                        },
                        show: {
                          width: '28vw',
                        },
                      }}
                    >
                      <AnimatePresence mode="popLayout">
                        {current === i && (
                          <motion.div
                            className={s.content}
                            key={`Accordion-name-${i}`}
                            initial={{
                              opacity: 0,
                              x: '-100%',
                            }}
                            animate={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 0.3,
                                ease: easingTransitions?.easeOutQuart,
                                delay: 0.4,
                              },
                            }}
                            exit={{
                              opacity: 0,
                              x: '-100%',
                              transition: {
                                duration: 0.3,
                                ease: easingTransitions?.easeInQuart,
                                delay: 0.1,
                              },
                            }}
                          >
                            <div
                              className={s.name}
                              dangerouslySetInnerHTML={{ __html: item.name }}
                            />
                            <div
                              className={s.describe}
                              dangerouslySetInnerHTML={{
                                __html: item.describe,
                              }}
                            />
                            {item.learnMore && (
                              <motion.p
                                className={s.accordion_a}
                                layout
                                dangerouslySetInnerHTML={{
                                  __html: item.learnMore,
                                }}
                                onClick={() => {
                                  setDialogWrapShow(true)
                                  setYouTubeId(item.youTubeId)
                                }}
                              />
                            )}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  </motion.div>
                )
              )
            })
            .filter(Boolean)}
        </div>
      </div>
      <div className="only-in-pad">
        <motion.div
          layout
          className={cn(
            s.serieslist_content,
            gs?.serieslist_content,
            'content'
          )}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.6 }}
          variants={{
            show: {
              transition: {
                duration: 0.3,
                staggerChildren: 0.05,
              },
            },
            hidden: {
              transition: {
                duration: 0.3,
              },
            },
          }}
        >
          <div className={s.lOverFlow_box} ref={ref}>
            {data?.map((item, i) => (
              <motion.div
                layout
                key={`content-${i}`}
                className={cn(s.serieslist_box, gs?.serieslist_box)}
              >
                <div className={s.series_img_box}>
                  <Picture
                    source={`${item?.img}, ${item?.mobileImg || item?.img} 768`}
                    className={cn(s.series_img)}
                  />
                </div>
                <motion.div className={s.serieslist_textBox}>
                  <motion.p
                    className={s.series_title}
                    layout
                    dangerouslySetInnerHTML={{
                      __html: item.name,
                    }}
                  />
                  <motion.p
                    className={s.series_subTitle}
                    layout
                    dangerouslySetInnerHTML={{
                      __html: item.describe,
                    }}
                  />
                  {item.learnMore && (
                    <motion.p
                      className={s.accordion_a}
                      layout
                      dangerouslySetInnerHTML={{
                        __html: item.learnMore,
                      }}
                      onClick={() => {
                        setDialogWrapShow(true)
                        setYouTubeId(item.youTubeId)
                      }}
                    />
                  )}
                </motion.div>
              </motion.div>
            ))}
          </div>
          <div className=" relative m-auto mt-[25px] hidden h-[4px] w-[100px] rounded-[4px] bg-[#E5E5E5] l:block">
            <motion.div
              style={{
                width: useTransform(scrollXProgress, [0, 1], ['0%', '100%']),
              }}
              className={cn(
                'absolute  left-0 top-0 h-full w-full rounded-[4px] bg-[#00A9E1]',
                gs?.scrollBg
              )}
            ></motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Accordion
