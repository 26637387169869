/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import styles from './HomeProductList.module.css'
import { motion, useScroll, useTransform, useParallax } from 'framer-motion'
import SectionTitle from '@components/layout/SectionTitle'
import Button from '@components/control/Button'
import { Picture } from '@components/ui'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useUI } from '@components/ui'
import { btoaID, getGaClientId } from '@lib/utils/tools'
import { StarRating } from '@components/product/Reviews'
import getProductPersonalizeInHome from '@lib/apis/get-product-personalize-in-home'
import {
  getAmazonLinkParams,
  pageGTMEvent,
  pageTwttr,
} from '@lib/utils/thirdparty'
import { useAddItem, useUpdateItem, useRemoveItem } from 'lib/shopify/api/cart'
import { useRouter } from 'next/router'
import throttle from 'lodash.throttle'
import { ProductPrice } from '@components/product'
import getProductCouponsBySkus from '@lib/apis/get-product-coupons-by-skus'
import { formatPrice } from '@commerce/product/use-price'

// let data = {
//   productList: [
//     {
//       handle: 'jade-test-anker-b-511-b-charger-nano-pro',
//       img: 'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/Mask_group.png?v=1668752469',
//     },
//     {
//       handle: 'a2637',
//       img: 'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/Mask_group.png?v=1668752469',
//     },
//     {
//       handle: 'copya2637',
//       img: 'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/Mask_group.png?v=1668752469',
//     },
//   ],
// }
const HomeProductList = ({
  relatedProducts,
  data,
  metafields,
  id,
  indexList,
}) => {
  const { discounts } = metafields
  let { locale } = useRouter()
  const [discountData, setDiscountData] = useState({})
  const [products, setProducts] = useState([])
  const { gtmReady } = useUI()

  let result = async () => {
    const clientId = await getGaClientId()
    let result = await getProductPersonalizeInHome({
      locale,
      clientId,
      limit: Number(data.limit),
    })
    setProducts(result?.data || [])
    if (result?.data) pageBundleGTM(result?.data, locale)
  }
  useEffect(() => {
    if (gtmReady) {
      setTimeout(() => {
        result()
      }, 1000)
    }
  }, [gtmReady])
  const itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: { x: '-120%', transition: { duration: 0.3 } },
  }
  const ref = useRef(null)
  const { scrollXProgress } = useScroll({
    container: ref,
  })
  const couponCallback = useCallback(async () => {
    const skus = products.map((product) => product?.sku)
    const result = await getProductCouponsBySkus(locale, skus)
    setDiscountData(result)
  }, [products])
  useEffect(() => {
    couponCallback()
  }, [couponCallback])
  const confirmCoupons = (coupon) => {
    if (coupon && coupon?.value_type === 'fixed_amount') {
      return `${coupon?.value_style} ${discounts?.off}`
    }
    if (coupon && coupon?.value_type === 'percentage') {
      return `${Math.abs(parseInt(coupon?.value))}% ${discounts?.off}`
    }
    return false
  }
  const handleSectItemGTM = (item, index) => {
    window.dataLayer.push({ event_parameters: null })
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'select_item',
      event_parameters: {
        page_group: 'Home Page',
        item_list_name: 'Home_Page_Bundle',
        items: [
          {
            item_id: item.sku, //读取被推荐产品的SKU
            item_name: item.product_title,
            item_variant: item.variant_title,
            price: formatPrice({
              amount: Number(
                item?.variant_price4wscode
                  ? item.variant_price4wscode
                  : item.variant_price
              ),
              currencyCode: item?.currency,
              locale,
            }), //读取被推荐产品展示的折扣价,无价格则空
            index: index, //读取被推荐的位置，如1,2,3,4,5
          },
        ],
      },
    })
  }
  return (
    <div
      className={cn('overflow-hidden', {
        ['hidden ']: products?.length == 0,
      })}
      id={id}
    >
      <SectionTitle
        subTitle={data?.subTitle}
        title={data?.title}
        className={styles.title_box}
      />
      <section className={cn(styles.homeProductList, 'layer')}>
        <div className={cn('content', styles.homeProductList_content)}>
          <div className={styles.lOverFlow_box} ref={ref}>
            {products?.length > 0 &&
              products?.map((item, index) => (
                <div key={`HomeProductList-${index}`}>
                  <motion.div
                    layout
                    className={cn(
                      'flex',
                      'cursor-pointer',
                      styles.homeProductList_product,
                      'l:hidden'
                    )}
                    onClick={() => {
                      window.location.href = `${
                        locale === 'us' ? '' : '/' + locale
                      }/products/${
                        item?.handle
                      }?ref=Home_Page_Bundle${indexList}_${index + 1}&variant=${
                        item?.variant_shopify_id
                      }`
                    }}
                    viewport={{ once: true, amount: 0.5 }}
                    initial="hidden"
                    whileInView="show"
                    variants={{
                      show: {
                        transition: {
                          duration: 0.3,
                          staggerChildren: 0.05,
                        },
                      },
                      hidden: {
                        transition: {
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    {item?.value && (
                      <div className={styles.homeProductList_product_off}>
                        {confirmCoupons(item)}
                      </div>
                    )}
                    <motion.div className={styles.homeProductList_product_xs}>
                      <div className={styles.homeProductList_product_xs_img}>
                        <Picture
                          // className="aspect-w-[400] aspect-h-[400] "
                          source={item?.variant_image}
                        />
                      </div>
                    </motion.div>
                    <motion.div className="m-auto w-[80%] overflow-hidden l:w-full">
                      <motion.div
                        layout
                        className={styles.homeProductList_product_xs_b}
                      >
                        <motion.p
                          className={styles.homeProductList_product_span}
                          layout
                          variants={itemVariants}
                          dangerouslySetInnerHTML={{
                            __html: item?.product_title,
                          }}
                        />
                        <motion.div layout variants={itemVariants}>
                          {item?.variant_price && (
                            <ProductPrice
                              className={styles.variant_price}
                              price={formatPrice({
                                amount: Number(
                                  item?.variant_price4wscode
                                    ? item.variant_price4wscode
                                    : item.variant_price
                                ),
                                currencyCode: item?.currency,
                                locale,
                              })}
                              basePrice={
                                item?.variant_price4wscode
                                  ? formatPrice({
                                      amount: Number(item?.variant_price),
                                      currencyCode: item?.currency,
                                      locale,
                                    })
                                  : null
                              }
                              availableForSale={true}
                              settings={metafields?.shopCommon}
                            />
                          )}
                        </motion.div>

                        <motion.div
                          className={styles.btn_div}
                          layout
                          variants={itemVariants}
                        >
                          {/* {item.variant_metafields.map(
                            (v, i) =>
                              v.key == 'landingPage' && (
                                <Button
                                  key={i}
                                  small
                                  className={styles.product_view_btn}
                                >
                                  <a
                                    href={`${v.value}?ref=Home_Page_Bundle${index + 1
                                      }`}
                                  >
                                    {data.viewProduct}
                                  </a>
                                </Button>
                              )
                          )} */}
                          <Button
                            // border
                            small
                            // theme={'drak'}
                            className={styles.product_view_btn}
                          >
                            <a
                              href={`${
                                locale === 'us' ? '' : '/' + locale
                              }/products/${
                                item?.handle
                              }?ref=Home_Page_Bundle${indexList}_${
                                index + 1
                              }&variant=${item?.variant_shopify_id}`}
                              className="block"
                              onClick={() => handleSectItemGTM(item, index)}
                            >
                              {data.Buy}
                            </a>
                          </Button>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    layout
                    className={cn(
                      styles.homeProductList_product,
                      'hidden',
                      'l:flex'
                    )}
                    viewport={{ once: true, amount: 0.5 }}
                    onClick={() => {
                      window.location.href = `${
                        locale === 'us' ? '' : '/' + locale
                      }/products/${
                        item?.handle
                      }?ref=Home_Page_Bundle${indexList}_${index + 1}&variant=${
                        item?.variant_shopify_id
                      }`
                    }}
                  >
                    {item?.value && (
                      <div className={styles.homeProductList_product_off}>
                        {confirmCoupons(item)}
                      </div>
                    )}
                    <motion.div className={styles.homeProductList_product_xs}>
                      <div className={styles.homeProductList_product_xs_img}>
                        <Picture
                          // className="aspect-w-[400] aspect-h-[400] "
                          source={item?.variant_image}
                        />
                      </div>
                    </motion.div>
                    <motion.div className="m-auto w-[80%] overflow-hidden l:w-full">
                      <motion.div
                        layout
                        className={styles.homeProductList_product_xs_b}
                      >
                        <motion.p
                          className={styles.homeProductList_product_span}
                          layout
                          variants={itemVariants}
                          dangerouslySetInnerHTML={{
                            __html: item?.product_title,
                          }}
                        />
                        <motion.div layout variants={itemVariants}>
                          {item?.variant_price && (
                            <ProductPrice
                              className={styles.variant_price}
                              price={formatPrice({
                                amount: Number(
                                  item?.variant_price4wscode
                                    ? item?.variant_price4wscode
                                    : item?.variant_price
                                ),
                                currencyCode: item?.currency,
                                locale,
                              })}
                              basePrice={
                                item?.variant_price4wscode
                                  ? formatPrice({
                                      amount: Number(item?.variant_price),
                                      currencyCode: item?.currency,
                                      locale,
                                    })
                                  : null
                              }
                              availableForSale={true}
                              settings={metafields?.shopCommon}
                            />
                          )}
                        </motion.div>
                        <motion.div className={styles.btn_div} layout>
                          {/* {item.variant_metafields.map(
                            (v, i) =>
                              v.key == 'landingPage' && (
                                <Button
                                  key={i}
                                  small
                                  className={styles.product_view_btn}
                                >
                                  <a
                                    href={`${v.value}?ref=Home_Page_Bundle${
                                      index + 1
                                    }`}
                                  >
                                    {data.viewProduct}
                                  </a>
                                </Button>
                              )
                          )} */}
                          <Button
                            // border
                            small
                            // theme={'drak'}
                            className={styles.product_view_btn}
                          >
                            <a
                              href={`${
                                locale === 'us' ? '' : '/' + locale
                              }/products/${
                                item?.handle
                              }?ref=Home_Page_Bundle${indexList}_${
                                index + 1
                              }&variant=${item?.variant_shopify_id}`}
                              className="block"
                              onClick={() => handleSectItemGTM(item, index)}
                            >
                              {data.Buy}
                            </a>
                          </Button>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.grid_line}>
          <div className="relative m-auto mt-[25px] h-[4px] w-[100px] rounded-[4px] bg-[#E5E5E5]">
            <motion.div
              style={{
                width: useTransform(scrollXProgress, [0, 1], ['0%', '100%']),
              }}
              className="absolute  left-0 top-0 h-full w-full rounded-l-[10px] rounded-r-[10px] bg-[#00A9E1]"
            ></motion.div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default HomeProductList

export const pageBundleGTM = (products, locale) => {
  window.dataLayer.push({ event_parameters: null })
  window.dataLayer.push({
    event: 'ga4Event',
    event_name: 'view_item_list',
    event_parameters: {
      page_group: 'Home Page',
      item_list_name: 'Home_Page_Bundle',
      items: products?.map((item, index) => {
        return {
          item_id: item.sku, //读取被推荐产品的SKU
          item_name: item.product_title,
          item_variant: item.variant_title,
          price: formatPrice({
            amount: Number(
              item?.variant_price4wscode
                ? item.variant_price4wscode
                : item.variant_price
            ),
            currencyCode: item?.currency,
            locale,
          }), //读取被推荐产品展示的折扣价,无价格则空
          index: index, //读取被推荐的位置，如1,2,3,4,5
        }
      }),
    },
  })
}
