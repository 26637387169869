import Decimal from 'decimal.js'
import { Picture, Text, ButtonV2 } from '@components/ui'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { appendRefParameter } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import useProduct from '@shopify/product/use-product'
import { useFilterCollection } from 'lib/shopify/api/collection'
import { useUI } from '@components/ui/context'
import { useRouter } from 'next/router'
import { getGaClientId } from '@lib/utils/tools'
import getProductPersonalizeInHome from '@lib/apis/get-product-personalize-in-home'
import getProductCouponsByProducts from '@lib/apis/get-product-coupons-by-products'
import useAllDiscountedProducts from '@lib/hooks/useAllDiscountedProducts'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Icon from '@components/icons'

import HomeTitle from '../HomeTitle/HomeTitle'
import ProductCard from './ProductCard'
import { NextArrow, PrevArrow } from '../Arrow'
import s from 'templates/Pages/HomeCharger/HomeCharger.module.css'

import cn from 'classnames'
import 'swiper/css'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode, Mousewheel } from 'swiper/modules'

const Index = ({ data, id, pageHandle, shopData, pageType, gtmRef }) => {
  const [isStart, setStart] = useState(true)
  const [isEnd, setEnd] = useState(false)
  const [activeTab, setActiveTab] = useState(data?.tabsList?.[0]?.nav || '')
  const [resultProducts, setResultProducts] = useState([])
  const [coupons, setCoupons] = useState({})
  const [showGtm, setShowGtm] = useState(false)

  // 推荐产品
  const { gtmReady } = useUI()
  const { locale } = useRouter()
  const [recommendsProductsHandle, setRecommendsProductsHandle] = useState([])
  const [recommendsProductsInfo, setRecommendsProductsInfo] = useState([])
  const [dealsProductsHandle, setDealsProductsHandle] = useState([])

  // 折扣产品
  let dealsProducts = useAllDiscountedProducts()

  // 品类产品
  const { data: resultData, isLoading } = useFilterCollection(
    data?.tabsList?.find((item) => item?.nav === activeTab)?.handle,
    { first: Number(data?.first) || 12 }
  )

  // 推荐产品
  const filter_values =
    pageType == 'hes' || pageType == 'powerStation'
      ? ['big_charger', 'middle_charger']
      : ['small_charger']
  const getRecommendsProductsHandle = async () => {
    const clientId = await getGaClientId()
    const result = await getProductPersonalizeInHome({
      locale,
      clientId,
      limit: Number(data?.first) || 12,
      filter_values,
    })
    setRecommendsProductsHandle(
      result?.data?.length > 0 ? result?.data?.map((item) => item?.handle) : []
    )
    setRecommendsProductsInfo(result?.data?.length > 0 && result?.data)
  }
  useEffect(() => {
    let api_recommend = data?.tabsList?.some(
      (item) => item?.prodType === 'isRecommends'
    )
    if (api_recommend && gtmReady) {
      setTimeout(() => {
        getRecommendsProductsHandle()
      }, 1000)
    }
  }, [data?.tabsList, gtmReady])

  const { data: recommendsProducts } = useProduct({
    handles: recommendsProductsHandle?.join(','),
  })

  const { data: dealsProductsData } = useProduct({
    handles: dealsProductsHandle?.join(','),
  })

  useEffect(() => {
    const activeItem = data?.tabsList?.find((item) => item?.nav === activeTab)
    const isRecommends = activeItem?.prodType === 'isRecommends' || false
    const isDeals = activeItem?.prodType === 'isDeals' || false
    if (isRecommends) {
      let products = []
      recommendsProductsInfo?.forEach((item) => {
        let productOld =
          recommendsProducts?.products?.find(
            (p) => p?.handle === item?.handle
          ) || {}
        let product = Object.assign({}, productOld)
        if (product) {
          product.sku = item?.sku || ''
          product.variant_shopify_id = item?.variant_shopify_id || ''
          products?.push(product)
        }
      })
      const resultProductsproducts = products
        .filter((product) => product?.availableForSale)
        .filter((_, index) => index < (data?.first || 12))
      setResultProducts(resultProductsproducts)
    } else if (isDeals) {
      const products = dealsProducts
        .sort((a, b) => b.fixed_value - a.fixed_value)
        .filter((product) => product?.available_for_sale)
        .filter((product) =>
          product?.product_tags
            ?.split(', ')
            ?.some((tag) => activeItem?.tags?.includes(tag))
        )
        .filter((_, index) => index < (data?.first || 12))
      setDealsProductsHandle(
        products?.length > 0 ? products?.map((item) => item?.handle) : []
      )
      setResultProducts(products)
    } else {
      const products = resultData?.products
        .filter((product) => product?.availableForSale)
        .filter((_, index) => index < (data?.first || 12))
      setResultProducts(products)
    }
  }, [
    resultData?.products,
    recommendsProducts?.products,
    dealsProducts,
    activeTab,
  ])

  const handles = useMemo(() => {
    return resultProducts?.map((item) => item.handle)
  }, [resultProducts])

  const getCoupons = useCallback(async () => {
    if (handles?.length > 0) {
      const result = await getProductCouponsByProducts(
        locale,
        handles,
        'handles[]'
      )
      setCoupons(result || {})
    }
  }, [handles?.length])

  useEffect(() => {
    getCoupons()
  }, [getCoupons])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.create({
      trigger: `#${id}`,
      start: 'bottom bottom',
      end: '+=0',
      onEnter: () => {
        setShowGtm(true)
      },
    })
  }, [])

  const showProductGtm = () => {
    const activeItem = data?.tabsList?.find((item) => item?.nav === activeTab)
    let currency
    const items = resultProducts?.map((item, index) => {
      const proData = {
        ...shopData?.pageCommon,
        ...shopData?.shopCommon,
        ...data,
      }
      const product = normalizeStorefrontProduct(item, coupons, proData)
      currency = product?.currencyCode || ''
      return {
        item_id: product?.sku || '',
        item_name: product?.title || '',
        item_brand: product?.brand || '',
        item_category: product?.category || '',
        item_variant: product?.variantName || '',
        price: product?.price || '',
        index: index,
      }
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'view_item_list',
      event_parameters: {
        page_group: 'Home Page',
        item_list_name:
          activeItem?.prodType === 'isRecommends'
            ? 'Home_Page_Bundle'
            : gtmRef || data?.gtmTitle || '',
        currency: currency,
        items: items,
      },
    })
  }

  useEffect(() => {
    if (!showGtm || !resultProducts) return
    showProductGtm()
  }, [resultProducts, showGtm])

  const { setRelativeLink } = useRelativeLink()

  return (
    <>
      <div className="layer relative z-[1]">
        <div className="content">
          <HomeTitle
            data={data}
            pageHandle={pageHandle}
            theme={data?.theme}
            hideLink={true}
            gtmRef={gtmRef}
          ></HomeTitle>

          {/* buttons */}
          {data?.boxButtons && (
            <div className="mb-[160px] flex gap-[12px]">
              {data?.boxButtons?.map((item, index) => (
                <ButtonV2 key={index} variant={item?.theme}>
                  <a
                    href={appendRefParameter({
                      url: setRelativeLink({ link: item?.link }),
                      refValue: gtmRef + '_' + activeTab?.nav || pageHandle,
                    })}
                    dangerouslySetInnerHTML={{ __html: item?.label }}
                  />
                </ButtonV2>
              ))}
            </div>
          )}

          {/* nav */}
          {data?.tabsList && (
            <div className="mb-[48px] xl:mb-[24px]">
              <div className="flex justify-between">
                <div
                  className={cn(
                    s.navBox,
                    'flex w-full flex-1 gap-[32px] md:gap-[20px] md:overflow-x-auto md:overflow-y-hidden'
                  )}
                >
                  {data?.tabsList?.map((item, index) => {
                    if (
                      item?.prodType === 'isRecommends' &&
                      recommendsProducts?.products?.length <= 0
                    )
                      return
                    if (
                      item?.prodType === 'collection' &&
                      resultData?.products?.length <= 0
                    )
                      return
                    if (
                      item?.prodType === 'isDeals' &&
                      dealsProducts?.length <= 0
                    )
                      return
                    return (
                      <div
                        key={index}
                        className="relative cursor-pointer pb-[12px] md:flex-none [&:hover_span]:w-full"
                        onClick={() => setActiveTab(item?.nav)}
                      >
                        <Text
                          className="text-[16px] font-[600] leading-[1.2] text-[#1D1D1F]"
                          html={item?.nav}
                        />
                        <span
                          className={cn(
                            'active absolute bottom-0 left-0 h-[3px] bg-[#00BEFA] transition-all duration-[0.4s]',
                            activeTab === item?.nav ? 'w-full' : 'w-0'
                          )}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className="min-h-[36px] md:hidden">
                  {data?.tabsList?.map((item, index) => {
                    if (item?.nav !== activeTab || !item?.link) return
                    return (
                      <a
                        key={index}
                        className={cn(
                          'flex items-center justify-center pb-[12px] text-[16px] font-[600] leading-[1.4] transition-all duration-[0.4s]',
                          data?.theme === 'dark'
                            ? 'text-[#999] [&:hover]:text-[#ddd] [&:hover_path]:stroke-[#ddd]'
                            : 'text-[#777] [&:hover]:text-[#1D1D1F] [&:hover_path]:stroke-[#1D1D1F]'
                        )}
                        href={appendRefParameter({
                          url: setRelativeLink({ link: item?.link }),
                          refValue: gtmRef + '_' + item?.nav || pageHandle,
                        })}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: data?.boxLinkTxt }}
                        />
                        <Icon
                          iconKey="arrowRight"
                          width={24}
                          height={24}
                        ></Icon>
                      </a>
                    )
                  })}
                </div>
              </div>
              {data?.tabsList && (
                <div className="mt-[-1px] h-[1px] w-full bg-[#1D1D1F] bg-opacity-20"></div>
              )}
            </div>
          )}

          {/* product list */}
          <div className={s.swiperProductBox}>
            <div className={cn('relative max-w-[1280px]', s.listBox)}>
              <Swiper
                className="!overflow-visible"
                navigation={{
                  nextEl: `.${id}-custom-swiper-button-next`,
                  prevEl: `.${id}-custom-swiper-button-prev`,
                }}
                onProgress={(swiper) => {
                  setStart(swiper.isBeginning)
                  setEnd(swiper.isEnd)
                }}
                modules={[Navigation, FreeMode, Mousewheel]}
                mousewheel={{
                  forceToAxis: true,
                }}
                freeMode={true}
                slidesPerGroup={2}
                breakpoints={{
                  0: {
                    spaceBetween: '12px',
                    slidesPerView: 1.32,
                  },
                  768: {
                    spaceBetween: '12px',
                    slidesPerView: 2.65,
                  },
                  1023: {
                    spaceBetween: '16px',
                    slidesPerView: 3,
                    freeMode: false,
                  },
                }}
              >
                {resultProducts?.map((item, index) => {
                  const proData = {
                    ...shopData?.pageCommon,
                    ...shopData?.shopCommon,
                    ...data,
                  }
                  const product = normalizeStorefrontProduct(
                    item,
                    coupons,
                    proData
                  )
                  const activeItem = data?.tabsList?.find(
                    (item) => item?.nav === activeTab
                  )
                  const productNote =
                    activeItem?.prodType === 'isDeals'
                      ? dealsProductsData?.products?.find(
                          (i) => i.handle === product.handle
                        )?.metafields?.productNote
                      : ''
                  if (!product) return null
                  return (
                    <SwiperSlide
                      key={id + 'SwiperSlide' + index}
                      className="!flex !h-[unset]"
                    >
                      <ProductCard
                        data={proData}
                        pageHandle={pageHandle}
                        product={product}
                        cns={s}
                        defaultRef={
                          gtmRef
                            ? gtmRef +
                              '_' +
                              (data?.tabsList ? activeTab + '_' : '') +
                              (index + 1)
                            : pageHandle
                        }
                        gmtIndex={index + 1}
                        itemListName={
                          activeItem?.prodType === 'isRecommends'
                            ? 'Home_Page_Bundle'
                            : gtmRef || data?.gtmTitle || ''
                        }
                        productNote={productNote}
                      ></ProductCard>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <PrevArrow
                isDisabled={isStart}
                className={cn(
                  `${id}-custom-swiper-button-prev`,
                  s.arrow,
                  'absolute left-[32px] top-1/2 z-[1] -translate-y-1/2 md:hidden'
                )}
                theme={data?.cardTheme}
              ></PrevArrow>
              <NextArrow
                isDisabled={isEnd}
                className={cn(
                  `${id}-custom-swiper-button-next`,
                  s.arrow,
                  'absolute right-[32px] top-1/2 z-[1] -translate-y-1/2 md:hidden'
                )}
                theme={data?.cardTheme}
              ></NextArrow>
            </div>
          </div>

          <div>
            {data?.tabsList?.map((item, index) => {
              if (item?.nav !== activeTab || !item?.link) return
              return (
                <a
                  key={index}
                  className={cn(
                    'mt-[24px] hidden items-center justify-center text-[16px] font-[600] leading-[1.4] transition-all duration-[0.4s] md:flex',
                    data?.theme === 'dark'
                      ? 'text-[#999] [&:hover]:text-[#ddd] [&:hover_path]:stroke-[#ddd]'
                      : 'text-[#777] [&:hover]:text-[#1D1D1F] [&:hover_path]:stroke-[#1D1D1F]'
                  )}
                  href={appendRefParameter({
                    url: setRelativeLink({ link: item?.link }),
                    refValue: gtmRef + '_' + item?.nav || pageHandle,
                  })}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: data?.boxLinkTxt }}
                  />
                  <Icon iconKey="arrowRight" width={24} height={24}></Icon>
                </a>
              )
            })}
          </div>
        </div>
      </div>
      {data?.bgImg && (
        <div className="absolute left-0 top-0 w-full">
          <Picture
            source={`${data?.bgImg}, ${data?.bgImgMob || data?.bgImg} 768`}
            className="w-full"
          ></Picture>
        </div>
      )}
    </>
  )
}

export default Index

const normalizeStorefrontProduct = (item, coupons, data) => {
  const product = item
  const variant = item?.sku
    ? product?.variants?.find((v) => v?.sku === item?.sku) || {}
    : product?.variants?.find((v) => v?.availableForSale) || {}

  const firstCoupon =
    coupons[product?.slug] &&
    coupons[product?.slug]?.find((v) => v?.sku === variant?.sku)
  const discount = firstCoupon
    ? firstCoupon
    : coupons[product?.slug] && coupons[product?.slug][0]

  return {
    ...item,
    image: variant?.image?.url || product?.variant_image,
    mobileImage: variant?.image?.url || product?.variant_image,
    title:
      (data?.nameSorce === 'variant' && variant?.metafields?.infos?.name) ||
      product?.name ||
      product?.product_title,
    handle: product?.slug?.toLowerCase() || product?.handle,
    id: product?.id || product?.product_shopify_id,
    sku: variant?.sku || product?.sku,
    metafields: product?.metafields || product?.product_metafields,
    variantMetafields: variant?.metafields || product?.variant_metafields,
    availableForSale: product?.availableForSale || product?.available_for_sale,
    basePrice:
      product?.variant_price ||
      (discount?.variant_price4wscode || !variant.listPrice
        ? variant?.price
        : variant?.listPrice),
    price:
      discount?.variant_price4wscode ||
      variant.price ||
      product?.variant_price4wscode,
    originPrice: variant?.price,
    discountType: discount?.value_type || product?.value_type || 'fixed_amount',
    currencyCode: product?.price?.currencyCode || product?.currency,
    discountValue:
      Math.abs(discount?.value) ||
      (variant?.listPrice &&
        new Decimal(variant?.listPrice)
          .minus(variant?.price)
          .toFixed(0, Decimal.ROUND_DOWN)) ||
      Math.abs(product?.value),
    code: discount?.title || product?.title,
    variantId: variant?.id || product?.variant_shopify_id,
    variantName: variant?.name,
    tags: product?.tags || product?.product_tags?.split(', '),
    variantNote: variant?.metafields?.infos?.variantNote,
  }
}
