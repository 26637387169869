import cn from 'classnames'
import { Picture, Text } from '@components/ui'
import HomeTitle from '../HomeTitle/HomeTitle'
import s from 'templates/Pages/HomeCharger/HomeCharger.module.css'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useEffect, useState } from 'react'

const Index = ({ data, pageHandle, id, gtmRef }) => {
  const [txtBoxH, setTxtBoxH] = useState(0)
  const [playAni, setPlayAni] = useState(false)

  useEffect(() => {
    const width = document.querySelector('body').offsetWidth
    const height = window.screen.height

    if (width < 768) return

    const domW = document?.querySelector(`#${id} .coverImgBox`)?.offsetWidth
    const domH = document?.querySelector(`#${id} .coverImgBox`)?.offsetHeight
    const paddingT =
      width > 1920 ? 140 : width > 768 ? (140 / 1920) * width : 64
    setTxtBoxH(
      document?.querySelector(`#${id} .txtBox`)?.offsetHeight + paddingT
    )

    gsap.registerPlugin(ScrollTrigger)
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#${id} .layer`,
          start: 'top bottom',
          end: `top top-=20%`,
          // markers: true,
          scrub: 0,
        },
      })
      .to(`#${id} .bgIcon1`, {
        transform: 'translate(-57%, -50%)',
      })
      .to(
        `#${id} .bgIcon2`,
        {
          transform: 'translate(57%, -6%)',
        },
        '<'
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#${id} .layer`,
          start: 'top top',
          end: `+=${1 * height}px`,
          // markers: true,
          scrub: 0,
          pin: true,
        },
      })
      .to(`#${id} .coverImg`, {
        top: '0px',
        width: `${domW}px`,
        height: `${domH}px`,
        duration: '0.7',
        onComplete: () => setPlayAni(true),
      })
      .to(`#${id} .aniOpactiy, #${id} .boxLink`, {
        opacity: 1,
        duration: '0',
        onStart: () => setPlayAni(true),
      })
      .to(`#${id} .aniOpactiy, #${id} .boxLink`, {
        opacity: 1,
        duration: '0.3',
      })
  }, [])

  return (
    <>
      <style>{`
        #${id} .aniOpactiy, #${id} .boxLink {
          opacity: 0;
        }
      `}</style>
      <div className={cn('layer relative w-full md:!pt-0', s.paddingTop)}>
        <div className="content md:hidden">
          <div className="txtBox aniOpactiy w-full">
            <HomeTitle
              data={data}
              pageHandle={pageHandle}
              playAni={playAni}
              gtmRef={gtmRef}
            />
          </div>
          <div className="flex w-full gap-[16px] leading-[1.4] l:gap-[12px]">
            <div className="aniOpactiy col flex w-[24.063%] flex-col gap-[16px] l:gap-[12px] x:w-[23.9%]">
              <div className="row aspect-h-[320] aspect-w-[308] bg-[#fff]">
                <Item item={data?.list?.[0]} />
              </div>
              <div className="row aspect-h-[200] aspect-w-[308] bg-[#fff]">
                <Item item={data?.list?.[1]} />
              </div>
            </div>
            <div className="col flex flex-1 flex-col justify-between gap-[16px] l:gap-[12px]">
              <div className="coverImgBox row aspect-h-[320] aspect-w-[632]">
                <div
                  className="coverImg absolute left-1/2 z-[1] h-[100vh] w-[100vw] -translate-x-1/2 bg-[#00BEFA]"
                  style={{
                    top: `-${txtBoxH}px`,
                  }}
                >
                  <div className="relative h-full w-full overflow-hidden">
                    <Picture
                      source={data?.list?.[2]?.bgIcon1}
                      className="bgIcon1 absolute left-0 top-0 w-[67%]"
                      style={{
                        transform: 'translate(-57%, -80%)',
                      }}
                    />
                    <Picture
                      source={data?.list?.[2]?.bgIcon2}
                      className="bgIcon2 absolute right-0 top-0 w-[67%]"
                      style={{
                        transform: 'translate(57%, -40%)',
                      }}
                    />
                    <div className="absolute left-1/2 top-1/2 w-[25%] -translate-x-1/2 -translate-y-1/2">
                      <Picture
                        source={data?.list?.[2]?.bgTxt}
                        className="w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="aniOpactiy row flex gap-[16px] l:gap-[12px]">
                <div className="flex-1">
                  <div className="aspect-h-[200] aspect-w-[308] bg-[#fff]">
                    <Item item={data?.list?.[3]} />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="aspect-h-[200] aspect-w-[308] bg-[#fff]">
                    <Item item={data?.list?.[4]} />
                  </div>
                </div>
              </div>
            </div>
            <div className="aniOpactiy col flex w-[24.063%] flex-col gap-[16px] l:gap-[12px] x:w-[23.9%]">
              <div className="row aspect-h-[200] aspect-w-[308] bg-[#fff]">
                <Item item={data?.list?.[5]} />
              </div>
              <div className="row aspect-h-[320] aspect-w-[308] bg-[#fff] xl:text-[16px]">
                <Item item={data?.list?.[6]} />
              </div>
            </div>
          </div>
        </div>

        <div className="content hidden md:block">
          <div className="txtBox w-full">
            <HomeTitle data={data} pageHandle={pageHandle} />
          </div>
          <div className="flex w-full flex-col gap-[12px]">
            <div className="row aspect-h-[180] aspect-w-[342]">
              <Item item={data?.list?.[2]} />
            </div>
            <div className="row flex gap-[12px]">
              <div className="col flex-1 bg-[#fff]">
                <div className="aspect-h-[194] aspect-w-[165]">
                  <Item item={data?.list?.[1]} />
                </div>
              </div>
              <div className="col flex-1 bg-[#fff]">
                <div className="aspect-h-[194] aspect-w-[165]">
                  <Item item={data?.list?.[3]} />
                </div>
              </div>
            </div>
            <div className="row flex gap-[12px]">
              <div className="col flex flex-1 flex-col gap-[12px]">
                <div className="aspect-h-[268] aspect-w-[165] bg-[#fff]">
                  <Item item={data?.list?.[0]} />
                </div>
                <div className="aspect-h-[194] aspect-w-[165] bg-[#fff]">
                  <Item item={data?.list?.[5]} />
                </div>
              </div>
              <div className="col flex flex-1 flex-col gap-[12px]">
                <div className="aspect-h-[194] aspect-w-[165] bg-[#fff]">
                  <Item item={data?.list?.[4]} />
                </div>
                <div className="row aspect-h-[268] aspect-w-[165] bg-[#fff]">
                  <Item item={data?.list?.[6]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Index

const Item = ({ item }) => {
  return (
    <>
      {(item?.bg || item?.bgMob) && (
        <Picture
          source={`${item?.bg}, ${item?.bgMob || item?.bg} 769`}
          className="h-full w-full object-cover"
        />
      )}
      {item?.icon && (
        <Picture
          source={item?.icon}
          className="bottom-[24px] left-auto right-[32px] top-auto h-[60px] w-[60px] xl:bottom-[12px] xl:right-[16px] xl:h-[48px] xl:w-[48px]"
        />
      )}
      <div className="w-full p-[32px] xl:p-[16px]">
        {item?.preTit && (
          <Text
            html={item?.preTit?.toUpperCase()}
            className="mb-[4px] text-[16px] font-[500] text-[#777] xl:text-[14px]"
            variant="sectionSubHeading"
          />
        )}
        {item?.tag && (
          <Text
            html={item?.tag?.toUpperCase()}
            className="mb-[4px] text-[16px] font-[500] text-[#00BEFA] xl:text-[14px]"
            variant="sectionSubHeading"
          />
        )}
        <Text
          html={item?.tit}
          className="text-[20px] font-[600] text-[#1D1D1F] xl:text-[16px]"
        />
      </div>
    </>
  )
}
