import { Picture, Text } from '@components/ui'
import { useRouter } from 'next/router'
import cn from 'classnames'
import { useFilterCollection } from 'lib/shopify/api/collection'
import BannerLink from '@components/common/BannerLink'
import HomeTitle from '../HomeTitle/HomeTitle'
import { useMemo } from 'react'

const Index = ({ data, pageHandle, id, gtmRef }) => {
  return (
    <div className="layer">
      <div className="content">
        <HomeTitle
          data={data}
          pageHandle={pageHandle}
          id={id}
          playAni={true}
        ></HomeTitle>
        {data?.list && (
          <div className="flex flex-wrap gap-[16px] l:gap-[12px]">
            {data?.list?.map((item, index) => (
              <div
                key={id + index}
                className={cn(
                  'cardItem_' + index,
                  'flex flex-1 flex-col gap-[16px] md:w-[calc(50%-6px)] md:flex-auto l:gap-[12px]'
                )}
              >
                {item?.items?.map((o, i) => (
                  <CardItem
                    key={o?.label + i}
                    item={o}
                    data={data}
                    pageHandle={pageHandle}
                    isCols={item?.items?.length > 1}
                    gtmRef={gtmRef}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const CardItem = ({ item, data, isCols, pageHandle, gtmRef }) => {
  const excludeNoIndex = (product) => {
    return !product?.metafields?.seoSetting?.noindex
  }
  const { data: resultData } = useFilterCollection(item?.handle, { first: 250 })
  const { locale } = useRouter()
  const totalData = useMemo(
    () => resultData?.products?.filter(excludeNoIndex)?.length || 0,
    [resultData]
  )

  const link = `${locale === 'us' ? '' : '/' + locale}/collections/${
    item?.handle
  }?ref=${gtmRef ? gtmRef + '_' + (item?.index + 1) : pageHandle}`

  return (
    <div className="flex-1">
      <div
        className={cn(
          isCols
            ? 'aspect-h-[200] aspect-w-[308] w-full md:aspect-h-[128] md:aspect-w-[165]'
            : 'aspect-h-[416] aspect-w-[308] w-full md:aspect-h-[268] md:aspect-w-[165]',
          'relative flex cursor-pointer items-end justify-end overflow-hidden bg-[#E5E7EC]',
          '[&:hover_img]:scale-[1.05]'
        )}
      >
        <div className="w-full">
          {item?.image && (
            <Picture
              source={`${item?.image}, ${item?.imageMob || item?.image} 768`}
              className={cn(
                isCols ? 'h-auto w-[60%] xl:w-[48.49%]' : 'w-full',
                'absolute bottom-0 left-auto right-0 top-auto'
              )}
              imgClassName="transition-all duration-[0.4s]"
            ></Picture>
          )}
        </div>
        <div className="absolute left-0 top-0 h-auto w-auto p-[24px] leading-[1.4] xl:p-[16px]">
          {item?.label && (
            <Text
              className="mb-[4px] text-[20px] font-[600] text-[#1D1D1F] xl:text-[16px]"
              html={item?.label}
              variant="sectionSubHeading"
            ></Text>
          )}
          <div className="flex gap-[5px] text-[16px] font-[500] text-[#777] xl:text-[14px]">
            <span>{totalData}</span>
            <span>{data?.product}</span>
          </div>
        </div>
        <BannerLink to={link} />
      </div>
    </div>
  )
}

export default Index
