import { Picture } from '@components/ui'
import SectionTitle from '@components/layout/SectionTitle'
import styles from './Categorylist.module.css'
import cn from 'classnames'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useRelativeLink } from '@commerce/product/use-relative-link'
// let data = [
//   {
//     icon: 'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Rectangle_85.png?v=1668571163',
//     icon_mob:
//       'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/2022-11-02_6.52_1.png?v=1668739329',
//     link: '',
//     text: 'Power Stations',
//     target: '',
//   },
//   {
//     icon: 'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Rectangle_6142.png?v=1668571163',
//     icon_mob:
//       'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/2022-11-02_6.52_1.png?v=1668739329',
//     link: '',
//     text: 'Chargers',
//     target: '',
//   },
//   {
//     icon: 'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Rectangle_6185.png?v=1668571163',
//     link: '',
//     icon_mob:
//       'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/2022-11-02_6.52_1.png?v=1668739329',
//     text: 'PowerBanks',
//     target: '',
//   },
//   {
//     icon: 'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Rectangle_6186.png?v=1668571163',
//     link: '',
//     icon_mob:
//       'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/2022-11-02_6.52_1.png?v=1668739329',
//     text: 'Cables',
//     target: '',
//   },
//   {
//     icon: 'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Rectangle_6187_6ac28f7c-d664-45ff-a9fa-1a4dd82bd243.png?v=1668571163',
//     link: '',
//     icon_mob:
//       'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/2022-11-02_6.52_1.png?v=1668739329',
//     text: 'Wireless',
//     target: '',
//   },
//   {
//     icon: 'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Rectangle_6188.png?v=1668571163',
//     link: '',
//     icon_mob:
//       'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/2022-11-02_6.52_1.png?v=1668739329',
//     text: 'AC Power',
//     target: '',
//   },
//   {
//     icon: 'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Rectangle_6189.png?v=1668571163',
//     link: '',
//     icon_mob:
//       'https://cdn.shopify.com/s/files/1/0674/6793/6033/files/2022-11-02_6.52_1.png?v=1668739329',
//     text: 'Hubs and Docks',
//     target: '',
//   },
// ]
const Categorylist = ({ data }) => {
  const { setRelativeLink } = useRelativeLink()
  const itemVariants = {
    show: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
    hidden: { opacity: 0, transition: { duration: 0.3 } },
  }
  return (
    <div className={cn('overflow-hidden', styles.root)}>
      <SectionTitle
        subTitle={data?.subTitle}
        title={data?.title}
        className={styles?.title_box}
      />
      <section
        className={cn(styles.categorylist_layer, 'layer', {
          ['pb-[40px]']: data?.hasPadding,
        })}
      >
        <motion.div
          layout
          className={cn(styles.categorylist_content, 'content', '')}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.6 }}
          variants={{
            show: {
              transition: {
                duration: 0.5,
                staggerChildren: 0.05,
              },
            },
            hidden: {
              transition: {
                duration: 0.5,
              },
            },
          }}
        >
          <motion.div className="md:px-[20px]">
            <motion.div className="md_content_grid">
              <motion.div className={cn(styles.categorylist_content_mob)}>
                {data?.categoryList &&
                  data?.categoryList.map((v, i) => (
                    <a
                      href={`${setRelativeLink({
                        link: v?.link,
                      })}?ref=homePageBanner`}
                      target="_self"
                      key={`categorylist-${i}`}
                      className={styles.categorylist_a}
                    >
                      <motion.div
                        className={styles.categorylist_div}
                        layout
                        variants={itemVariants}
                      >
                        <motion.div className="flex flex-col items-center overflow-hidden l:flex-row ">
                          <Picture
                            source={`${v?.icon}`}
                            className={styles.categorylist_icon}
                          />
                          <p
                            className={styles.categorylist_span}
                            dangerouslySetInnerHTML={{
                              __html: v?.text,
                            }}
                          ></p>
                        </motion.div>
                        <ChevronRightIcon className="hidden w-[18px] md:block md:w-[16px]" />
                      </motion.div>
                    </a>
                  ))}
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </section>
    </div>
  )
}
export default Categorylist
