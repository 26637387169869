import { Picture, Text } from '@components/ui'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { atobID } from '@lib/utils/tools'
import { appendRefParameter } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import useProduct from '@shopify/product/use-product'
import { normalizeStorefrontProduct as normalize } from '@components/normalize'
import { PlayIcon } from '@heroicons/react/24/outline'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import Icon from '@components/icons'

import BannerLink from '@components/common/BannerLink'
import HomeTitle from '../HomeTitle/HomeTitle'
import { NextArrow, PrevArrow } from '../Arrow'

import cn from 'classnames'
import s from 'templates/Pages/HomeCharger/HomeCharger.module.css'
import 'swiper/css'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode, Mousewheel } from 'swiper/modules'

const Index = ({ data, id, pageHandle, setYouTubeId, gtmRef }) => {
  const [isStart, setStart] = useState(true)
  const [isEnd, setEnd] = useState(false)

  const handles =
    data?.cardType === 'reviews'
      ? data?.list?.map((item) => item?.product?.handle)?.join(',')
      : ''
  const { data: result } = useProduct({ handles })

  const { setRelativeLink } = useRelativeLink()

  return (
    <div className="layer">
      <div className="content">
        <HomeTitle
          data={data}
          pageHandle={pageHandle}
          gtmRef={gtmRef}
        ></HomeTitle>
        {data?.list && (
          <div className={cn(s.swiperBox, s.listBox)}>
            <div className="relative max-w-[1280px]">
              <Swiper
                className="!overflow-visible"
                navigation={{
                  nextEl: `.${id}-custom-swiper-button-next`,
                  prevEl: `.${id}-custom-swiper-button-prev`,
                }}
                onProgress={(swiper) => {
                  setStart(swiper.isBeginning)
                  setEnd(swiper.isEnd)
                }}
                modules={[Navigation, FreeMode, Mousewheel]}
                freeMode={true}
                mousewheel={{
                  forceToAxis: true,
                }}
                breakpoints={{
                  0: {
                    spaceBetween: '12px',
                    slidesPerView: 1.2,
                    slidesPerGroup: 1,
                  },
                  768: {
                    spaceBetween: '12px',
                    slidesPerView: 2.65,
                    freeMode: false,
                    slidesPerGroup: data?.rowNumber === 2 ? 1 : 2,
                  },
                  1440: {
                    spaceBetween: '16px',
                    slidesPerView: `${data?.rowNumber || 3}`,
                    freeMode: false,
                  },
                }}
              >
                {data?.list?.map((jItem, jIndex) => (
                  <SwiperSlide
                    key={id + 'SwiperSlide' + jIndex}
                    className="!flex !h-[unset]"
                  >
                    <CardItem
                      item={jItem}
                      data={data}
                      pageHandle={pageHandle}
                      result={result}
                      setYouTubeId={setYouTubeId}
                      index={jIndex}
                      gtmRef={gtmRef}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {data?.list?.length > 3 && (
                <>
                  <PrevArrow
                    isDisabled={isStart}
                    className={cn(
                      `${id}-custom-swiper-button-prev`,
                      s.arrow,
                      'absolute left-[32px] top-1/2 z-[1] -translate-y-1/2 md:hidden'
                    )}
                    theme={data?.theme}
                  ></PrevArrow>
                  <NextArrow
                    isDisabled={isEnd}
                    className={cn(
                      `${id}-custom-swiper-button-next`,
                      s.arrow,
                      'absolute right-[32px] top-1/2 z-[1] -translate-y-1/2 md:hidden'
                    )}
                    theme={data?.theme}
                  ></NextArrow>
                </>
              )}
            </div>
          </div>
        )}
        {data?.boxLinkUrl && (
          <a
            className="mt-[24px] hidden items-center justify-center text-[20px] font-[600] leading-[1.4] text-[#777] md:flex md:text-[16px]"
            href={appendRefParameter({
              url: setRelativeLink({ link: data?.boxLinkUrl }),
              refValue: gtmRef || pageHandle,
            })}
          >
            <span dangerouslySetInnerHTML={{ __html: data?.boxLinkTxt }} />
            <Icon iconKey="arrowRight" width={24} height={24}></Icon>
          </a>
        )}
      </div>
    </div>
  )
}

const CardItem = ({
  item,
  data,
  result,
  pageHandle,
  setYouTubeId,
  index,
  gtmRef,
}) => {
  switch (data?.cardType) {
    case 'reviews':
      return (
        <ReviewsCard
          item={item}
          data={data}
          pageHandle={pageHandle}
          result={result}
          index={index}
          gtmRef={gtmRef}
        />
      )
    case 'recommended':
      return (
        <RecommendedCard
          item={item}
          data={data}
          pageHandle={pageHandle}
          setYouTubeId={setYouTubeId}
          gtmRef={gtmRef}
        />
      )
    default:
      return (
        <DealsCard
          item={item}
          data={data}
          pageHandle={pageHandle}
          result={result}
          index={index}
          gtmRef={gtmRef}
        />
      )
  }
}

const DealsCard = ({ item, data, pageHandle, index, gtmRef }) => {
  const { setRelativeLink } = useRelativeLink()
  return (
    <div className={cn(s.cardActive, 'relative w-full cursor-pointer')}>
      <div className="w-full overflow-hidden">
        {item?.image && (
          <Picture
            source={`${item?.image}, ${item?.imageMob || item?.image} 768`}
            className={cn(
              'aspect-h-[480] aspect-w-[416] w-full l:aspect-h-[360] l:aspect-w-[283]',
              s.cardImage
            )}
            imgClassName="w-full h-full object-cover"
          ></Picture>
        )}
      </div>
      <div
        className={cn(
          item?.theme === 'dark' ? 'text-[#fff]' : 'text-[#1D1D1F]',
          'absolute left-0 top-0 box-border w-full p-[32px] leading-[1.4] xl:px-[16px] xl:py-[24px]'
        )}
      >
        {item?.preTit && (
          <Text
            className={cn(
              'mb-[4px] text-[16px] font-[500] xl:text-[14px]',
              item?.theme === 'dark' ? 'text-[#999]' : 'text-[#777]'
            )}
            html={item?.preTit?.toUpperCase()}
          ></Text>
        )}
        {item?.tit && (
          <Text
            className="text-[24px] font-[600] md:text-[18px] xl:text-[20px]"
            html={item?.tit}
          ></Text>
        )}
        {item?.desc && (
          <Text
            className="mt-[4px] text-[16px] font-[500] xl:text-[14px]"
            html={item?.desc}
          ></Text>
        )}
      </div>
      {item?.link && (
        <BannerLink
          to={appendRefParameter({
            url: setRelativeLink({ link: item?.link }),
            refValue: gtmRef ? gtmRef + '_' + (index + 1) : pageHandle,
          })}
        ></BannerLink>
      )}
    </div>
  )
}

const ReviewsCard = ({ item, data, pageHandle, result, index, gtmRef }) => {
  const { setRelativeLink } = useRelativeLink()
  const product = normalize(item?.product, result)

  const { locale } = useRouter()

  const getLink = ({ product }) => {
    if (!product) return
    const ref = gtmRef ? gtmRef + '_' + (index + 1) : pageHandle
    if (product?.link) {
      const rUrl = setRelativeLink({ link: product?.link })
      const delimiter = rUrl?.includes('?') ? '&ref=' : '?ref='
      return rUrl + delimiter + ref
    } else if (product?.variantId) {
      return `${
        locale === 'us' ? '' : '/' + locale
      }/products/${product?.handle?.toLowerCase()}?variant=${atobID(
        product?.variantId || ''
      )}&ref=${ref}`
    }
    return `${
      locale === 'us' ? '' : '/' + locale
    }/products/${product?.handle?.toLowerCase()}?ref=${ref}`
  }

  return (
    <div
      className={cn(
        s.cardActive,
        'relative w-full cursor-pointer bg-[#fff] leading-[1.2]',
        'aspect-h-[480] aspect-w-[416] l:aspect-h-[360] l:aspect-w-[283]'
      )}
    >
      <div
        className={cn(
          item?.theme === 'dark' ? 'text-[#fff]' : 'text-[#1D1D1F]',
          'box-border flex h-full w-full flex-col p-[32px] xl:px-[16px] xl:py-[24px]'
        )}
      >
        <div className="flex h-[20%] items-center gap-[12px]">
          <div className="relative h-[68px] w-[68px] overflow-hidden rounded-full bg-[#ddd] text-center xl:h-[54px] xl:w-[54px]">
            <div className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              {item?.avatar ? (
                <Picture
                  source={item?.avatar}
                  className="w-full"
                  imgClassName="w-full h-full object-cover"
                />
              ) : (
                <Text
                  className="text-[20px] font-[600] xl:text-[18px]"
                  html={item?.name?.substring(0, 2)}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[8px]">
            <Text
              className="text-[20px] font-[600] xl:text-[16px]"
              html={item?.name}
            />
            <div className="flex gap-[4px]">
              <CreateStarts reviewStar={item?.reviewStar}></CreateStarts>
            </div>
          </div>
        </div>
        <div className="flex-1 pb-[24px] pt-[56px] leading-[1.6] xl:pb-[12px] xl:pt-[30px]">
          <Text
            className="text-[16px] font-[500] xl:line-clamp-6"
            html={item?.reviews}
          />
        </div>
        <div className="relative flex items-center gap-[12px] border-t border-[#1D1D1F] border-opacity-20 pt-[24px] xl:pt-[12px]">
          <div className="h-[80px] w-[80px] xl:h-[48px] xl:w-[48px]">
            <Picture
              source={product?.image}
              className="h-full w-full object-cover"
              imgClassName="w-full h-full object-cover"
            />
          </div>
          <Text
            className="text-[16px] font-[500] leading-[1.4] xl:text-[14px]"
            html={product?.title}
          />
        </div>
      </div>
      <BannerLink to={getLink({ product })}></BannerLink>
    </div>
  )
}

const RecommendedCard = ({ item, setYouTubeId, gtmRef, pageHandle }) => {
  return (
    <div
      className={cn(
        s.cardActive,
        'relative w-full bg-[#fff]',
        'aspect-h-[416] aspect-w-[632] xl:aspect-h-[360] xl:aspect-w-[283]'
      )}
    >
      <div className="flex w-full xl:flex-col">
        <div className="relative w-[48.735%] cursor-pointer overflow-hidden xl:w-full">
          {item?.image && (
            <Picture
              source={`${item?.image}, ${item?.imageMob || item?.image} 1439`}
              className={cn(
                s.cardImage,
                'aspect-h-[416] aspect-w-[308] w-full xl:aspect-h-[180] xl:aspect-w-[283]'
              )}
              imgClassName="w-full h-full object-cover"
            ></Picture>
          )}
          {item?.youtubeId && (
            <a
              className={cn(
                'absolute bottom-[20px] right-[30px] flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#000] bg-opacity-70',
                'md:bottom-[16px] md:right-[16px]'
              )}
              onClick={() => {
                setYouTubeId(item?.youtubeId)
                pageGTMEvent({
                  event: 'ga4Event',
                  event_name: 'lp_button',
                  event_parameters: {
                    page_group: 'Activity Page_' + pageHandle,
                    position: item?.youTubeTitle || '',
                    button_name: 'video',
                  },
                })
              }}
            >
              <PlayIcon className="ml-[2px] w-[20px] fill-white" />
            </a>
          )}
        </div>
        <div className="flex flex-1 flex-col px-[32px] py-[48px] leading-[1.4] xl:p-[16px] xl:pb-[24px]">
          <div className="h-[50%] xl:mb-[16px] xl:h-auto">
            {item?.tit && (
              <Text
                className="text-[24px] font-[600] xl:text-[16px]"
                html={item?.tit}
              ></Text>
            )}
            {item?.desc && (
              <Text
                className="mt-[4px] text-[16px] font-[500] text-[#777] xl:text-[14px]"
                html={item?.desc}
              ></Text>
            )}
          </div>
          {item?.txt && (
            <Text
              className="!relative flex text-[16px] font-[500] leading-[1.6] xl:line-clamp-3"
              html={item?.txt}
            ></Text>
          )}
        </div>
      </div>
    </div>
  )
}

const CreateStarts = ({ reviewStar = 5 }) => {
  const elements = []

  while (reviewStar >= 0) {
    if (reviewStar > 1 || reviewStar === 0) {
      elements.push(
        <svg
          key={reviewStar}
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M11.6121 4.22554L8.01478 3.67806L6.40666 0.264102C6.36274 0.170629 6.29048 0.0949613 6.20122 0.0489671C5.97736 -0.0667603 5.70532 0.0296792 5.59339 0.264102L3.98528 3.67806L0.38791 4.22554C0.288731 4.24038 0.198053 4.28934 0.128627 4.36352C0.0446959 4.45386 -0.00155415 4.57539 3.98784e-05 4.70142C0.0016339 4.82745 0.0509416 4.94766 0.137128 5.03563L2.73987 7.69291L2.12496 11.4452C2.11054 11.5324 2.11976 11.6222 2.15159 11.7043C2.18341 11.7864 2.23655 11.8575 2.305 11.9095C2.37344 11.9616 2.45444 11.9925 2.53882 11.9988C2.6232 12.0051 2.70757 11.9865 2.78238 11.9452L6.00003 10.1736L9.21768 11.9452C9.30553 11.9941 9.40754 12.0104 9.5053 11.9926C9.75183 11.9481 9.9176 11.7033 9.8751 11.4452L9.26019 7.69291L11.8629 5.03563C11.9338 4.96293 11.9805 4.86798 11.9947 4.76412C12.033 4.50447 11.8601 4.26412 11.6121 4.22554Z"
            fill="#F90"
          />
        </svg>
      )
    } else if (reviewStar < 1) {
      elements.push(
        <div className="relative" key={reviewStar}>
          <div
            className="absolute left-0 top-0 overflow-hidden"
            style={{ width: `${reviewStar * 100}%` }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M11.6121 4.22554L8.01478 3.67806L6.40666 0.264102C6.36274 0.170629 6.29048 0.0949613 6.20122 0.0489671C5.97736 -0.0667603 5.70532 0.0296792 5.59339 0.264102L3.98528 3.67806L0.38791 4.22554C0.288731 4.24038 0.198053 4.28934 0.128627 4.36352C0.0446959 4.45386 -0.00155415 4.57539 3.98784e-05 4.70142C0.0016339 4.82745 0.0509416 4.94766 0.137128 5.03563L2.73987 7.69291L2.12496 11.4452C2.11054 11.5324 2.11976 11.6222 2.15159 11.7043C2.18341 11.7864 2.23655 11.8575 2.305 11.9095C2.37344 11.9616 2.45444 11.9925 2.53882 11.9988C2.6232 12.0051 2.70757 11.9865 2.78238 11.9452L6.00003 10.1736L9.21768 11.9452C9.30553 11.9941 9.40754 12.0104 9.5053 11.9926C9.75183 11.9481 9.9176 11.7033 9.8751 11.4452L9.26019 7.69291L11.8629 5.03563C11.9338 4.96293 11.9805 4.86798 11.9947 4.76412C12.033 4.50447 11.8601 4.26412 11.6121 4.22554Z"
                fill="#F90"
              />
            </svg>
          </div>
          <svg
            key={reviewStar}
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M11.6121 4.22554L8.01478 3.67806L6.40666 0.264102C6.36274 0.170629 6.29048 0.0949613 6.20122 0.0489671C5.97736 -0.0667603 5.70532 0.0296792 5.59339 0.264102L3.98528 3.67806L0.38791 4.22554C0.288731 4.24038 0.198053 4.28934 0.128627 4.36352C0.0446959 4.45386 -0.00155415 4.57539 3.98784e-05 4.70142C0.0016339 4.82745 0.0509416 4.94766 0.137128 5.03563L2.73987 7.69291L2.12496 11.4452C2.11054 11.5324 2.11976 11.6222 2.15159 11.7043C2.18341 11.7864 2.23655 11.8575 2.305 11.9095C2.37344 11.9616 2.45444 11.9925 2.53882 11.9988C2.6232 12.0051 2.70757 11.9865 2.78238 11.9452L6.00003 10.1736L9.21768 11.9452C9.30553 11.9941 9.40754 12.0104 9.5053 11.9926C9.75183 11.9481 9.9176 11.7033 9.8751 11.4452L9.26019 7.69291L11.8629 5.03563C11.9338 4.96293 11.9805 4.86798 11.9947 4.76412C12.033 4.50447 11.8601 4.26412 11.6121 4.22554Z"
              fill="#ddd"
            />
          </svg>
        </div>
      )
    }
    reviewStar -= 1
  }

  return <>{elements}</>
}

export default Index
