import { Picture, Text, ButtonV2 } from '@components/ui'
import { useState, useEffect } from 'react'
import { appendRefParameter } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import useProduct from '@shopify/product/use-product'
import useCoupons from '@lib/hooks/useCoupon'
import { normalizeStorefrontProduct } from '@components/normalize'
import jump from 'jump.js'
import { pageGTMEvent } from '@lib/utils/thirdparty'

import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import HomeTitle from '../HomeTitle/HomeTitle'
import ProductCard from './ProductCard'
import { NextArrow, PrevArrow } from '../Arrow'
import Icon from '@components/icons'
import s from 'templates/Pages/HomeCharger/HomeCharger.module.css'
import BannerLink from '@components/common/BannerLink'

import cn from 'classnames'
import 'swiper/css'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode, Mousewheel } from 'swiper/modules'

const Index = ({ data, id, pageHandle, shopData, gtmRef }) => {
  const [swiperRef, setSwiperRef] = useState(null)
  const [isStart, setStart] = useState(true)
  const [isEnd, setEnd] = useState(false)
  const [aniBoxH, setAniBoxH] = useState(0)
  const [activeItem, setActiveItem] = useState(data?.tabsList?.[0] || {})
  const [isDark, setIsDark] = useState(activeItem?.theme === 'dark' || false)
  const [resultProducts, setResultProducts] = useState(
    data?.products || data?.tabsList?.[0]?.products || []
  )
  const [showGtm, setShowGtm] = useState(false)

  const allProducts = data?.tabsList?.map((item) => item?.products).flat()
  const handles = allProducts?.map((item) => item.handle) || []
  const skus = allProducts?.map((item) => item.sku) || []
  const { data: result, isLoading } = useProduct({
    handles: handles?.join(','),
  })
  const { coupons } = useCoupons(skus)

  const { setRelativeLink } = useRelativeLink()

  const changeItem = (nav) => {
    const item = data?.tabsList?.find((tab) => tab?.nav === nav) || {}
    setIsDark(item?.theme === 'dark')
    setActiveItem(item)
    setResultProducts(item?.products)
    swiperRef?.slideTo(0)
    jump(`#${id} .aniBox`, { duration: 500, offset: 20 })
  }

  const showProductGtm = () => {
    let currency
    const items = resultProducts?.map((item, index) => {
      const proData = {
        ...shopData?.pageCommon,
        ...shopData?.shopCommon,
        ...data,
      }
      const product = normalizeStorefrontProduct(item, result, coupons, proData)
      currency = product?.currencyCode || ''
      return {
        item_id: product?.sku || '',
        item_name: product?.title || '',
        item_brand: product?.brand || '',
        item_category: product?.category || '',
        item_variant: product?.variantName || '',
        price: product?.price || '',
        index: index,
      }
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'view_item_list',
      event_parameters: {
        page_group: 'Home Page',
        currency: currency,
        item_list_name: gtmRef || data?.gtmTitle || '',
        items: items,
      },
    })
  }

  useEffect(() => {
    if (!showGtm || !result) return
    showProductGtm()
  }, [result, showGtm])

  useEffect(() => {
    if (!data?.animation) return
    const height = window.screen.height
    const domH = document?.querySelector(`#${id} .aniBox`)?.offsetHeight
    setAniBoxH(domH + height)

    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.create({
      trigger: `#${data?.animation?.trigger}`,
      start: 'bottom top+=60px',
      end: '+=0',
      // markers: true,
      // scrub: 0,
      onEnter: () => {
        setShowGtm(true)
        if (document?.querySelector(`#header`)?.style) {
          document.querySelector(`#header`).style.opacity = 0
          document.querySelector(`#header`).style.pointerEvents = 'none'
          gsap.to(`#${id} .aniOpactiy`, { opacity: 1, duration: '0.01' })
        }
      },
      onEnterBack: () => {
        if (document?.querySelector(`#header`)?.style) {
          document.querySelector(`#header`).style.opacity = 1
          document.querySelector(`#header`).style.pointerEvents = 'auto'
          gsap.to(`#${id} .aniOpactiy`, { opacity: 0, duration: '0.01' })
        }
      },
    })

    ScrollTrigger.create({
      trigger: `#${id}`,
      start: 'bottom top',
      end: '+=0',
      // markers: true,
      // scrub: 0,
      onEnter: () => {
        if (document?.querySelector(`#header`)?.style)
          document.querySelector(`#header`).style.opacity = 1
        document.querySelector(`#header`).style.pointerEvents = 'auto'
      },
      onEnterBack: () => {
        if (document?.querySelector(`#header`)?.style)
          document.querySelector(`#header`).style.opacity = 0
        document.querySelector(`#header`).style.pointerEvents = 'none'
      },
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#${data?.animation?.trigger}`,
          start: 'bottom bottom',
          end: `bottom top`,
          // markers: true,
          scrub: 0,
        },
      })
      .to(`#${id} .aniContent, #${id} .aniBg`, {
        transform: 'translateY(0)',
        duration: '1',
      })
  }, [])

  return (
    <>
      <style>{`
        #${id} {
          z-index: 0;
        }
        #${data?.animation?.trigger}, #${data?.animation?.coverDom} {
          z-index: 1;
        }
        #${id} .aniTrigger {
          margin-top: ${data?.animation ? '-100vh' : '0'};
        }
        #${id} .aniOpactiy {
          opacity: ${data?.animation ? 0 : 1};
          transition: all .4s;
        }
        #${id} .aniBg {
          transform: ${data?.animation ? 'translateY(20vh)' : 'translateY(0)'};
        }
        #${id} .aniContent {
          transform: ${data?.animation ? 'translateY(40vh)' : 'translateY(0)'};
        }
      `}</style>

      <div className="aniTrigger">
        <div
          className="aniY relative"
          style={{
            height: `${aniBoxH}px`,
          }}
        >
          {/* nav */}
          <div className="aniNav layer sticky top-[-2px] z-[1] w-full overflow-hidden">
            <div className="content relative z-[1]">
              <div className="flex justify-center pt-[24px]">
                <div className="flex gap-[32px]">
                  {data?.tabsList?.map((item, index) => (
                    <div
                      key={index}
                      className="relative cursor-pointer pb-[12px] [&:hover_span]:w-full"
                      onClick={() => changeItem(item?.nav)}
                    >
                      <Text
                        className={cn(
                          'text-[16px] font-[600] leading-[1.2] transition-all duration-[0.4s]',
                          isDark ? 'text-[#fff]' : 'text-[#1D1D1F]'
                        )}
                        html={item?.nav}
                      />
                      <span
                        className={cn(
                          'absolute bottom-[-1px] left-0 h-[2px] bg-[#00BEFA] transition-all duration-[0.4s]',
                          activeItem?.nav === item?.nav ? 'w-full' : 'w-0'
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={cn(
                  'h-[1px] w-full bg-opacity-20',
                  isDark ? 'bg-[#fff] bg-opacity-20' : 'bg-[#1D1D1F]'
                )}
              ></div>
            </div>
            {(activeItem?.bgImg || activeItem?.prodBgImg) && (
              <div className="absolute left-0 top-0 w-full">
                <Picture
                  source={`${activeItem?.bgImg || activeItem?.prodBgImg}, ${
                    activeItem?.bgImgMob ||
                    activeItem?.prodBgImgMob ||
                    activeItem?.bgImg ||
                    activeItem?.prodBgImg
                  } 768`}
                  className="h-full w-full"
                  imgClassName="object-cover w-full h-full"
                ></Picture>
              </div>
            )}
          </div>

          <div className={cn('aniBox sticky top-0', s.paddingBottom)}>
            <div className="relative z-[1]">
              {/* bg */}
              <div className="aniBg sticky top-0 h-0 w-full">
                <div className="relative w-full">
                  {data?.tabsList?.map((item, index) => (
                    <div
                      key={index}
                      className={cn(
                        'w-full',
                        index === 0 ? 'relative' : 'absolute left-0 top-0',
                        activeItem?.nav === item?.nav
                          ? 'opacity-100'
                          : 'opacity-0'
                      )}
                    >
                      {item?.bgImg && (
                        <Picture
                          source={`${item?.bgImg}, ${
                            item?.bgImgMob || item?.bgImg
                          } 768`}
                          className="h-full w-full"
                          imgClassName="object-cover w-full h-full"
                        ></Picture>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* content */}
              <div className="relative">
                <div className="relative">
                  <div className="aniContent aspect-h-[640] aspect-w-[1920] md:aspect-h-[520] md:aspect-w-[390]">
                    {data?.tabsList?.map((item, index) => (
                      <div key={index}>
                        {item?.prodBgImg && (
                          <div
                            className={cn(
                              'absolute left-0 top-0 h-full w-full',
                              activeItem?.nav === item?.nav
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          >
                            <Picture
                              source={`${item?.prodBgImg}, ${
                                item?.prodBgImgMob || item?.prodBgImg
                              } 768`}
                              className="w-full"
                            ></Picture>
                          </div>
                        )}
                        <div
                          className={cn(
                            'absolute bottom-[14%] left-auto right-[20%] top-auto h-auto w-[30%]',
                            'md:bottom-[10%] md:left-1/2 md:right-0 md:top-auto md:h-auto md:w-[80%] md:-translate-x-1/2',
                            activeItem?.nav === item?.nav
                              ? 'opacity-100'
                              : 'opacity-0'
                          )}
                        >
                          <Picture
                            source={`${item?.prodImg}, ${
                              item?.prodImgMob || item?.prodImg
                            } 768`}
                            className="w-full"
                          ></Picture>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="layer absolute top-1/2 z-[1] w-full -translate-y-1/2 md:top-[20%] md:-translate-y-0">
                    <div className="aniOpactiy content pl-[32px] xl:pl-0">
                      <HomeTitle
                        data={activeItem}
                        pageHandle={pageHandle}
                        theme={activeItem?.theme}
                        cns={{
                          txt: 'md:text-center',
                        }}
                      ></HomeTitle>
                      {/* buttons */}
                      {activeItem?.boxButtons && (
                        <div
                          className={cn(
                            'flex gap-[12px] md:justify-center',
                            activeItem?.textAlign === 'center' &&
                              'justify-center'
                          )}
                        >
                          {activeItem?.boxButtons?.map((item, index) => {
                            const ref = gtmRef
                              ? gtmRef + activeItem?.index + '_0'
                              : pageHandle
                            return (
                              <ButtonV2 key={index} variant={item?.theme}>
                                <a
                                  href={appendRefParameter({
                                    url: setRelativeLink({ link: item?.link }),
                                    refValue: ref,
                                  })}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.label,
                                  }}
                                />
                              </ButtonV2>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* product list */}
                <div className="aniOpactiy layer overflow-hidden">
                  <div className="content">
                    <div className={s.swiperProductBox}>
                      <div className={cn('relative max-w-[1280px]', s.listBox)}>
                        <Swiper
                          className="!overflow-visible"
                          navigation={{
                            nextEl: `.${id}-custom-swiper-button-next`,
                            prevEl: `.${id}-custom-swiper-button-prev`,
                          }}
                          onSwiper={setSwiperRef}
                          onProgress={(swiper) => {
                            setStart(swiper.isBeginning)
                            setEnd(swiper.isEnd)
                          }}
                          modules={[Navigation, FreeMode, Mousewheel]}
                          mousewheel={{
                            forceToAxis: true,
                          }}
                          freeMode={true}
                          slidesPerGroup={2}
                          breakpoints={{
                            0: {
                              spaceBetween: '12px',
                              slidesPerView: 1.32,
                            },
                            768: {
                              spaceBetween: '12px',
                              slidesPerView: 2.65,
                            },
                            1024: {
                              spaceBetween: '16px',
                              slidesPerView: 3,
                              freeMode: false,
                            },
                          }}
                        >
                          {resultProducts?.map((item, index) => {
                            const proData = {
                              ...shopData?.pageCommon,
                              ...shopData?.shopCommon,
                              ...data,
                            }
                            const product = normalizeStorefrontProduct(
                              item,
                              result,
                              coupons,
                              proData
                            )
                            if (!product) return null
                            return (
                              <SwiperSlide
                                key={id + 'SwiperSlide' + index}
                                className="!flex !h-[unset]"
                              >
                                <ProductCard
                                  data={proData}
                                  pageHandle={pageHandle}
                                  product={product}
                                  cns={s}
                                  isDark={activeItem?.prodCardTheme === 'dark'}
                                  defaultRef={
                                    gtmRef
                                      ? gtmRef +
                                        activeItem?.index +
                                        '_' +
                                        (index + 1)
                                      : pageHandle
                                  }
                                  gmtIndex={index + 1}
                                  itemListName={gtmRef || data?.gtmTitle}
                                ></ProductCard>
                              </SwiperSlide>
                            )
                          })}
                          {activeItem?.showAll?.link && (
                            <SwiperSlide className="!flex !h-[unset]">
                              <div
                                className={cn(
                                  'relative flex w-full flex-col overflow-hidden',
                                  isDark ? 'bg-[#1D1D1F]' : 'bg-[#fff]'
                                )}
                              >
                                <div className="relative">
                                  <div className="w-full">
                                    {activeItem?.showAll?.image && (
                                      <Picture
                                        source={`${
                                          activeItem?.showAll?.image
                                        }, ${
                                          activeItem?.showAll?.imageMob ||
                                          activeItem?.showAll?.image
                                        } 1023`}
                                        className="aspect-h-[420] aspect-w-[416] w-full"
                                        imgClassName="w-full h-full object-cover"
                                      ></Picture>
                                    )}
                                  </div>
                                  <div
                                    className={cn(
                                      activeItem?.showAll?.theme === 'dark'
                                        ? 'text-[#fff]'
                                        : 'text-[#1D1D1F]',
                                      'absolute left-0 top-0 box-border w-full p-[32px] leading-[1.4] md:p-[24px]'
                                    )}
                                  >
                                    {activeItem?.showAll?.preTit && (
                                      <Text
                                        className={cn(
                                          'mb-[4px] text-[16px] font-[500] xl:text-[12px]',
                                          activeItem?.showAll?.theme === 'dark'
                                            ? 'text-[#999]'
                                            : 'text-[#777]'
                                        )}
                                        html={activeItem?.showAll?.preTit}
                                      ></Text>
                                    )}
                                    {activeItem?.showAll?.tit && (
                                      <Text
                                        className="text-[24px] font-[600] leading-[1.2] xl:text-[18px]"
                                        html={activeItem?.showAll?.tit}
                                      ></Text>
                                    )}
                                    {activeItem?.showAll?.desc && (
                                      <Text
                                        className="mt-[4px] text-[16px] font-[500] xl:text-[12px]"
                                        html={activeItem?.showAll?.desc}
                                      ></Text>
                                    )}
                                  </div>
                                  <BannerLink
                                    to={appendRefParameter({
                                      url: setRelativeLink({
                                        link: activeItem?.showAll?.cardLink,
                                      }),
                                      refValue:
                                        gtmRef +
                                          activeItem?.index +
                                          '_' +
                                          (resultProducts?.length + 1) ||
                                        pageHandle,
                                    })}
                                  ></BannerLink>
                                </div>
                                <div className="flex flex-1 items-center px-[32px] py-[20px] md:px-[24px]">
                                  {activeItem?.showAll?.link && (
                                    <a
                                      rel="noopener noreferrer"
                                      href={appendRefParameter({
                                        url: setRelativeLink({
                                          link: activeItem?.showAll?.link,
                                        }),
                                        refValue:
                                          gtmRef +
                                            activeItem?.index +
                                            '_' +
                                            (resultProducts?.length + 1) ||
                                          pageHandle,
                                      })}
                                      className={cn(
                                        'flex gap-[4px] text-[20px] font-[600] leading-[1.4] md:text-[14px]',
                                        isDark
                                          ? 'text-[#fff]'
                                          : 'text-[#1D1D1F]'
                                      )}
                                    >
                                      <span>{data?.allProducts}</span>
                                      <Icon
                                        iconKey="arrowRight"
                                        width={24}
                                        height={24}
                                        className="mt-[1px] [&_path]:stroke-[#1D1D1F]"
                                      ></Icon>
                                    </a>
                                  )}
                                </div>
                              </div>
                            </SwiperSlide>
                          )}
                        </Swiper>
                        <PrevArrow
                          isDisabled={isStart}
                          className={cn(
                            `${id}-custom-swiper-button-prev`,
                            s.arrow,
                            'absolute left-[32px] top-1/2 z-[1] -translate-y-1/2 md:hidden'
                          )}
                          isDark={activeItem?.prodCardTheme === 'dark'}
                        ></PrevArrow>
                        <NextArrow
                          isDisabled={isEnd}
                          className={cn(
                            `${id}-custom-swiper-button-next`,
                            s.arrow,
                            'absolute right-[32px] top-1/2 z-[1] -translate-y-1/2 md:hidden'
                          )}
                          isDark={activeItem?.prodCardTheme === 'dark'}
                        ></NextArrow>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data?.tabsList?.map((item, index) => (
              <div
                key={'bg' + index}
                className="pointer-events-none absolute left-0 top-0 h-full w-full"
                style={{
                  background: activeItem?.boxBackground || '#fff',
                  opacity: activeItem?.nav === item?.nav ? 1 : 0,
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
