import { useEffect, useRef, useState, useMemo } from 'react'
import { useCountUp } from 'react-countup'
import throttle from 'lodash.throttle'

import { Picture } from '@components/ui'
import SectionTitle from '@components/layout/SectionTitle'
import styles from './BrandIntroduction.module.css'
import cn from 'classnames'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import { Hidden } from '@mui/material'
// let data = {
//   "title": "The World's No. 1 Mobile Charging Brand*",
//   list: [
//     {
//       num: '200',
//       title: 'Million+',
//       text: '200 Million+ Products<br> Sold Worldwide',
//       delay: '1000',
//     },
//     {
//       num: '146',
//       title: 'Countries',
//       text: 'Available in<br> 146 Countries',
//       delay: '1000',
//     },
//     {
//       num: '10',
//       title: 'Years of Innovation',
//       text: 'Pioneer in Charging<br> Technology for 10 Years ',
//       delay: '1000',
//     },
//   ],
//   tips: '*In terms of retail sales value in 2020. <br>Data source: Euromonitor International (Shanghai) Co., Ltd., measured in terms of retail  sales value in 2020, based on research conducted in 11-2021. Brands are defined as mobile charging brands if more than 75% of their retail sales are contributed by mobile phone charging products. Mobile phone charging products include<br>chargers, wireless chargers, power banks, and charging cables, and these accessories can also be used for other consumer electronics devices. ',
// }
const MotionDiv = ({ num, updateNum, delay }) => {
  const countUpRef = useRef(null)

  const { start, pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: num,
    delay: delay,
    duration: 2,
  })

  let onlyOne = true
  const handleScroll = () => {
    const viewPortHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    let domOffsetTop = countUpRef.current?.getBoundingClientRect()?.top
    if (domOffsetTop < viewPortHeight && onlyOne) {
      update(updateNum)
      onlyOne = false
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return (
    <motion.p
      className={styles.brandIntroduction_content_num}
      ref={countUpRef}
      dangerouslySetInnerHTML={{
        __html: num,
      }}
    />
  )
}
const BrandIntroduction = ({ data, id }) => {
  // const [isMobile, setIsMobile] = useState(false)

  // const [h_normal, h_active] = useMemo(() => {
  //   if (!isMobile) {
  //     return ['160vw', '100%']
  //   } else {
  //     return ['100%', '100%']
  //   }
  // }, [isMobile])

  // const resizeWindow = throttle(() => {
  //   const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
  //   if (offsetWidth <= 768) {
  //     setIsMobile(true)
  //   }
  // }, 300)

  // useEffect(() => {
  //   resizeWindow()
  //   window.addEventListener('resize', resizeWindow)

  //   return () => {
  //     window.removeEventListener('resize', resizeWindow)
  //   }
  // }, [resizeWindow])
  // const refBrandIntroduction = useRef(null)
  // const { scrollYProgress } = useScroll({
  //   target: refBrandIntroduction,
  //   offset: ['start end', 'end center'],
  // })

  return (
    <section
      className={cn(
        styles.brandIntroduction_layer,
        'brandIntroduction_layer',
        'overflow-hidden'
      )}
    >
      <div className={styles.brandIntroduction_bg} id={id}>
        <Picture
          source={`${data.pcBgImg},${data.mobBgImg} 768`}
          className={styles.brandIntroduction_bg_img}
        />

        <motion.div className={cn(styles.brandIntroduction_content)}>
          <motion.div
            className={cn(styles.brandIntroduction_content_bg_all, 'xs:hidden')}
          >
            <motion.div
              // ref={refBrandIntroduction}
              className={styles.brandIntroduction_content_bg}
              // style={{
              //   width: useTransform(
              //     scrollYProgress,
              //     [0, 0.5],
              //     [h_normal, h_active]
              //   ),
              // }}
            />
            <motion.div
              className={cn(styles.brandIntroduction_content_all, 'layer')}
            >
              {/* <motion.p
                className={styles.brandIntroduction_title}
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              ></motion.p> */}
              <motion.div className={styles.brandIntroduction_title}>
                <Picture
                  className="m-auto w-[130px] md:mb-[15px] md:w-[80px]"
                  source={data.ankerLogo}
                />
                <div
                  className={styles.world}
                  dangerouslySetInnerHTML={{
                    __html: data.titleTop,
                  }}
                ></div>
                <Picture
                  source={data.titleImg}
                  className={styles.brandIntroduction_title_icon}
                />
                <div
                  className={styles.world_title}
                  dangerouslySetInnerHTML={{
                    __html: data.titleBottom,
                  }}
                ></div>
              </motion.div>
              <motion.div className={styles.brandIntroduction_content_box}>
                {data.list &&
                  data.list.map((v, i) => (
                    <motion.div
                      key={`brandIntroduction-${i}`}
                      className={styles.brandIntroduction_content_flex}
                    >
                      <div className="flex items-end justify-center">
                        {v.uber && (
                          <span className="mr-[5px] text-[22px] font-[600] md:text-[14px] xl:text-[18px]">
                            {v.uber}
                          </span>
                        )}

                        <MotionDiv
                          num={v.num}
                          updateNum={v.num}
                          delay={v.delay}
                        />
                      </div>

                      <motion.div
                        className={styles.brandIntroduction_content_title}
                        dangerouslySetInnerHTML={{
                          __html: v?.title,
                        }}
                      />
                      <motion.div
                        className={styles.brandIntroduction_content_text}
                        dangerouslySetInnerHTML={{
                          __html: v?.text,
                        }}
                      />
                    </motion.div>
                  ))}
              </motion.div>
              <motion.div
                className={styles.brandIntroduction_tips}
                dangerouslySetInnerHTML={{
                  __html: data.tips,
                }}
              ></motion.div>
            </motion.div>
          </motion.div>
          <motion.div
            className={cn(
              styles.brandIntroduction_content_bg_all,
              'xs:block',
              'hidden'
            )}
          >
            <motion.div className={styles.brandIntroduction_content_bg} />
            <motion.div className="relative z-10 ">
              <motion.div className={styles.brandIntroduction_title}>
                <div
                  className={styles.world}
                  dangerouslySetInnerHTML={{
                    __html: data.titleTop,
                  }}
                ></div>
                <Picture
                  source={data.titleImg}
                  className={styles.brandIntroduction_title_icon}
                />
                <div
                  className={styles.world_title}
                  dangerouslySetInnerHTML={{
                    __html: data.titleBottom,
                  }}
                ></div>
              </motion.div>
              <motion.div className={styles.brandIntroduction_content_box}>
                {data.list &&
                  data.list.map((v, i) => (
                    <motion.div
                      key={`brandIntroduction-${i}`}
                      className={styles.brandIntroduction_content_flex}
                    >
                      <MotionDiv
                        num={v.num}
                        updateNum={v.num}
                        delay={v.delay}
                      />
                      <motion.div
                        className={styles.brandIntroduction_content_title}
                        dangerouslySetInnerHTML={{
                          __html: v?.title,
                        }}
                      />
                      <motion.div
                        className={styles.brandIntroduction_content_text}
                        dangerouslySetInnerHTML={{
                          __html: v?.text,
                        }}
                      />
                    </motion.div>
                  ))}
              </motion.div>
              <motion.div
                className={styles.brandIntroduction_tips}
                dangerouslySetInnerHTML={{
                  __html: data.tips,
                }}
              ></motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  )
}
export default BrandIntroduction
