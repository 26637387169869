import cn from 'classnames'
import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import isMobile from 'ismobilejs'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import Banner from '@components/banner/HomeBanner'
import s from './SwiperBanner.module.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const PrevArrow = ({ onClick, active, c = '' }) => (
  <button onClick={onClick} className={cn(c, s.arrow, s.arrowLeft)}>
    <ChevronLeftIcon />
  </button>
)

const NextArrow = ({ onClick, active, c = '' }) => (
  <button onClick={onClick} className={cn(c, s.arrow, s.arrowRight)}>
    <ChevronRightIcon />
  </button>
)

// 需要依赖特定数据结构 data
const SwiperBanner = ({ lang, relatedProducts }) => {
  const [mobile, setMobile] = useState(false)

  const getProduct = (handle) => {
    return relatedProducts?.find(
      (p) => handle?.toLowerCase() === p?.result?.handle?.toLowerCase()
    )?.result
  }

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: true,
    autoplay: lang?.autoplay || false,
    autoplaySpeed: lang?.autoplaySpeed || 5000,
    speed: lang?.speed || 300,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dotsClass: `${s.slickDots} slick-dots`,
    customPaging: function (i) {
      return <button className="slickBtn"></button>
    },
  }

  useEffect(() => {
    if (isMobile(window.navigator).phone) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [])

  return (
    <div className="relative flex flex-col">
      {lang?.bannerList?.length > 0 && (
        <Slider className={cn(s.slider)} {...settings}>
          {lang?.bannerList?.map((item, i) => (
            <div className={s.sliderCart} key={`swiperBanner-${i}`}>
              <Banner
                gtmRef={`swiperBanner${i + 1}`}
                product={getProduct(item?.handle)}
                lang={item}
                id={`swiperBanner-${i}`}
              />
            </div>
          ))}
        </Slider>
      )}
      <style jsx global>
        {`
          .${s.slider} .slick-dots li {
            width: 10px;
            height: 10px;
          }
          .${s.slider} .slick-dots li.slick-active .slickBtn {
            background-color: #00a7e1;
          }
          .${s.slider} .slick-dots {
            bottom: 36px;
            top: auto;
          }
          @media (max-width: 768px) {
            .${s.slider} .slick-dots {
              bottom: 26px;
              top: auto;
            }
          }
          .${s.slider} .slick-dots li .slickBtn {
            width: 8px;
            height: 8px;
            border-radio: 10px;
            white-space: nowrap;
            color: #000;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5em;
            margin: 0 auto;
            cursor: pointer;
            border-radius: 30px;
            background: rgba(200, 200, 200, 0.5);
            transition: all 300ms ease-out;
          }
          .${s.slider} .slick-dots li .slickBtn:before {
            display: none;
          }
        `}
      </style>
    </div>
  )
}

export default SwiperBanner
