import { Picture, Text, ButtonV2 } from '@components/ui'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import HomeTitle from '../HomeTitle/HomeTitle'
import cn from 'classnames'
import s from 'templates/Pages/HomeCharger/HomeCharger.module.css'

const Index = ({ data, id, pageHandle, gtmRef }) => {
  return (
    <div className="layer">
      <div className="content">
        <HomeTitle data={data} pageHandle={pageHandle}></HomeTitle>
        {data?.list && (
          <div className="flex flex-wrap gap-[16px] l:gap-[12px]">
            {data?.list?.map((item, index) => (
              <CardItem
                key={id + 'CardItem' + index}
                item={item}
                data={data}
                pageHandle={pageHandle}
                id={id}
                gtmRef={gtmRef}
                index={index}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const CardItem = ({ item, data, id, pageHandle, gtmRef, index }) => {
  const { setRelativeLink } = useRelativeLink()
  return (
    <div
      className={cn(
        'relative w-[calc(50%-8px)] cursor-pointer overflow-hidden text-center md:w-full',
        s.cardActive
      )}
    >
      <div className="w-full">
        {item?.image && (
          <Picture
            source={`${item?.image}, ${item?.imageMob || item?.image} 1023`}
            className={cn(
              s.cardImage,
              'aspect-h-[680] aspect-w-[632] w-full xl:aspect-h-[480] xl:aspect-w-[342]'
            )}
            imgClassName="w-full h-full object-cover"
          ></Picture>
        )}
      </div>
      <div
        className={cn(
          data?.theme === 'dark' ? 'text-[#fff]' : 'text-[#1D1D1F]',
          'absolute left-0 top-0 w-full px-[32px] py-[48px] leading-[1.4] md:py-[24px] xl:px-[16px] xl:py-[36px]'
        )}
      >
        {item?.tag && (
          <Text
            className="mb-[4px] text-[20px] font-[500] text-[#f90] xl:text-[14px]"
            html={item?.tag}
          ></Text>
        )}
        {item?.preTit && (
          <Text
            className="mb-[4px] text-[20px] font-[500] xl:text-[14px]"
            html={item?.preTit}
          ></Text>
        )}
        {item?.tit && (
          <Text
            className="text-[40px] font-[700] leading-[1.2] xl:text-[26px]"
            html={item?.tit}
          ></Text>
        )}
        {item?.desc && (
          <Text
            className="mt-[4px] text-[20px] font-[500] xl:text-[16px]"
            html={item?.desc}
          ></Text>
        )}
        {item?.buttons && (
          <div className="relative z-[1] mt-[32px] flex justify-center gap-[16px] xl:mt-[24px] xl:gap-[12px]">
            {item?.buttons?.map((button, i) => (
              <div key={id + 'button' + i}>
                <ButtonV2 variant={button?.theme}>
                  <a
                    href={`${setRelativeLink({
                      link: button?.link,
                    })}?ref=${
                      gtmRef ? gtmRef + '_' + (index + 1) : pageHandle
                    }`}
                    dangerouslySetInnerHTML={{ __html: button?.txt }}
                  />
                </ButtonV2>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Index
