import { Picture } from '@components/ui'
import SectionTitle from '@components/layout/SectionTitle'
import styles from './SeriesList.module.css'
import cn from 'classnames'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ArrowLeft, ArrowRight } from '@components/icons/next'
import { useEffect, useRef, useState } from 'react'
import throttle from 'lodash.throttle'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import Button from '@components/control/Button'

const SeriesList = ({ data }) => {
  const { setRelativeLink } = useRelativeLink()
  let itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: {
      x: '-130%',
      transition: { duration: 0.3 },
    },
  }
  const SampleNextArrow = (props) => {
    const { onClick } = props
    return (
      <div
        onClick={onClick}
        className={cn(
          'block',
          'absolute',
          ' top-0',
          'right-[1rem]',
          'cursor-pointer ',
          'z-10',
          ' w-[50px]',
          styles.arrowRight
        )}
      >
        <ArrowRight className="text-white" />
      </div>
    )
  }
  const SamplePrevArrow = (props) => {
    const { onClick } = props
    return (
      <div
        onClick={onClick}
        className={cn(
          'block',
          'absolute',
          'top-0',
          'left-[1rem]',
          'cursor-pointer',
          'z-10',
          'w-[50px]',
          styles.arrowLeft
        )}
      >
        <ArrowLeft className="text-white" />
      </div>
    )
  }
  const ref = useRef(null)
  const { scrollXProgress } = useScroll({
    container: ref,
  })
  return (
    <div className="overflow-hidden ">
      <SectionTitle
        subTitle={data.subTitle}
        title={data.title}
        className={styles.title_box}
      />
      <section
        className={cn(
          { [styles.hasPadding]: data?.hasPadding },
          styles.serieslist_layer,
          'layer',
          'grid'
        )}
      >
        <motion.div
          layout
          className={cn(styles.serieslist_content, 'content')}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.6 }}
          variants={{
            show: {
              transition: {
                duration: 0.3,
                staggerChildren: 0.05,
              },
            },
            hidden: {
              transition: {
                duration: 0.3,
              },
            },
          }}
        >
          <div className={styles.lOverFlow_box} ref={ref}>
            {data.seriesList &&
              data.seriesList.map((v, i) => (
                <motion.div
                  layout
                  key={`content-${i}`}
                  className={styles.serieslist_box}
                  onClick={() => {
                    window.location.href = `${setRelativeLink({
                      link: v.link,
                    })}?ref=homePageBanner`
                  }}
                >
                  <div className={styles.series_img_box}>
                    <Picture
                      source={`${v.img},${v.img_mob} 768`}
                      className={cn(
                        styles.series_img,
                        'aspect-w-[416]',
                        'aspect-h-[250]',
                        'md:aspect-w-[768]',
                        'md:aspect-h-[440]'
                      )}
                    />
                  </div>
                  <motion.div className={styles.serieslist_textBox}>
                    <motion.p
                      className={cn(styles.series_title, 'l:block', 'hidden')}
                      layout
                      dangerouslySetInnerHTML={{
                        __html: v.title,
                      }}
                    />
                    <motion.p
                      className={cn(styles.series_title, 'l:hidden', 'block')}
                      layout
                      variants={itemVariants}
                      dangerouslySetInnerHTML={{
                        __html: v.title,
                      }}
                    />
                    <motion.p
                      className={cn(
                        styles.series_subTitle,
                        'l:hidden',
                        'block'
                      )}
                      layout
                      variants={itemVariants}
                      dangerouslySetInnerHTML={{
                        __html: v.subTitle,
                      }}
                    />
                    <motion.p
                      className={cn(
                        styles.series_subTitle,
                        'l:block',
                        'hidden'
                      )}
                      layout
                      dangerouslySetInnerHTML={{
                        __html: v.subTitle,
                      }}
                    />
                    <motion.p
                      className={cn(styles.series_text, 'l:block', 'hidden')}
                      layout
                      dangerouslySetInnerHTML={{
                        __html: v.text,
                      }}
                    />
                    <motion.p
                      className={cn(styles.series_text, 'l:hidden', 'block')}
                      layout
                      variants={itemVariants}
                      dangerouslySetInnerHTML={{
                        __html: v.text,
                      }}
                    />
                    <motion.p
                      layout
                      className={cn(
                        styles.series_learnMore,
                        'l:block',
                        'hidden'
                      )}
                    >
                      {data?.isBtn ? (
                        <Button className="md:!max-w-[unset]">
                          <motion.a
                            className={cn(
                              styles.series_learnMore_a,
                              styles.searchFansBtn
                            )}
                            href={`${setRelativeLink({
                              link: v.link,
                            })}?ref=homePageBanner`}
                            target="_self"
                            dangerouslySetInnerHTML={{
                              __html:
                                v.learnMore +
                                `<span class="hidden">${v.title}</span>`,
                            }}
                          />
                        </Button>
                      ) : (
                        <motion.a
                          className={styles.series_learnMore_a}
                          href={`${setRelativeLink({
                            link: v.link,
                          })}?ref=homePageBanner`}
                          target="_self"
                          dangerouslySetInnerHTML={{
                            __html:
                              v.learnMore +
                              `<span class="hidden">${v.title}</span>`,
                          }}
                        />
                      )}
                    </motion.p>
                    <motion.p
                      layout
                      variants={itemVariants}
                      className={cn(
                        styles.series_learnMore,
                        'l:hidden',
                        'block'
                      )}
                    >
                      {data?.isBtn ? (
                        <Button>
                          <motion.a
                            className={cn(
                              styles.series_learnMore_a,
                              styles.searchFansBtn
                            )}
                            href={`${setRelativeLink({
                              link: v.link,
                            })}?ref=homePageBanner`}
                            target="_self"
                            dangerouslySetInnerHTML={{
                              __html:
                                v.learnMore +
                                `<span class="hidden">${v.title}</span>`,
                            }}
                          />
                        </Button>
                      ) : (
                        <motion.a
                          className={styles.series_learnMore_a}
                          href={`${setRelativeLink({
                            link: v.link,
                          })}?ref=homePageBanner`}
                          target="_self"
                          dangerouslySetInnerHTML={{
                            __html:
                              v.learnMore +
                              `<span class="hidden">${v.title}</span>`,
                          }}
                        />
                      )}
                    </motion.p>
                  </motion.div>
                </motion.div>
              ))}
          </div>
          <div className=" relative m-auto mt-[25px] hidden h-[4px] w-[100px] rounded-[4px] bg-[#E5E5E5] l:block">
            <motion.div
              style={{
                width: useTransform(scrollXProgress, [0, 1], ['0%', '100%']),
              }}
              className="absolute  left-0 top-0 h-full w-full rounded-l-[10px] rounded-r-[10px] bg-[#00A9E1]"
            ></motion.div>
          </div>
        </motion.div>
      </section>
      {/* <motion.div layout className={cn('hidden', 'l:block')}>
        <motion.div
          layout
          className={cn(styles.serieslist_content)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.6 }}
          variants={{
            show: {
              transition: {
                duration: 0.3,
                staggerChildren: 0.05,
              },
            },
            hidden: {
              transition: {
                duration: 0.3,
              },
            },
          }}
        >
          <style jsx global>
            {`
              .${styles.serieslist_content} .slick-slide {
                padding: 0 6px;
                transform: translateX(51%);
              }
              .${styles.serieslist_content}
                .slick-dots
                li.slick-active
                button:before {
                color: #00a9e1;
                opacity: 1;
              }
              @media (max-width: 768px) {
                .${styles.serieslist_content} .slick-slide {
                  border-raudis: 20px 20px 0 0;
                  padding: 0 5px;
                  transform: translateX(40%);
                }
                .${styles.serieslist_content} .slick-dots {
                  bottom: -30px;
                }
              }
              @media (max-width: 640px) {
                .${styles.serieslist_content} .slick-dots {
                  bottom: -30px;
                }
              }
            `}
          </style>
          <div className="grid grid-cols-14">
            <div className={styles.slickSlider}>
              <Slider {...settings}>
                {data.seriesList &&
                  data.seriesList.map((v, i) => (
                    <motion.div
                      layout
                      key={`content-${i}`}
                      className={styles.serieslist_box}
                      style={{ width: 350 }}
                    >
                      <div className="overflow-hidden">
                        <Picture
                          source={`${v.img},${v.img_mob} 768`}
                          className={cn(
                            styles.series_img,
                            'aspect-w-[534]',
                            'aspect-h-[360]'
                          )}
                        />
                      </div>
                      <motion.div className={styles.serieslist_textBox}>
                        <motion.p
                          className={styles.series_title}
                          layout
                          variants={ isMobile?null: itemVariants}
                          dangerouslySetInnerHTML={{
                            __html: v.title,
                          }}
                        />
                        <motion.p
                          className={styles.series_subTitle}
                          layout
                          variants={ isMobile?null: itemVariants}
                          dangerouslySetInnerHTML={{
                            __html: v.subTitle,
                          }}
                        />
                        <motion.p
                          className={styles.series_text}
                          layout
                          variants={ isMobile?null: itemVariants}
                          dangerouslySetInnerHTML={{
                            __html: v.text,
                          }}
                        />
                        <motion.p
                          layout
                          variants={ isMobile?null: itemVariants}
                          className={styles.series_learnMore}
                        >
                          <motion.a
                            className={styles.series_learnMore_a}
                            href={v.link}
                            target="_self"
                            dangerouslySetInnerHTML={{
                              __html: v.learnMore,
                            }}
                          />
                        </motion.p>
                      </motion.div>
                    </motion.div>
                  ))}
              </Slider>
            </div>
          </div>
        </motion.div>
      </motion.div> */}
    </div>
  )
}
export default SeriesList
