/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import throttle from 'lodash.throttle'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Text, Picture } from '@components/ui'
import { easingTransitions } from '@lib/utils/animate'
import {
  motion,
  LayoutGroup,
  AnimatePresence,
  useAnimationControls,
  useTransform,
} from 'framer-motion'
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/solid'
import SectionTitle from '@components/layout/SectionTitle'
import Button from '@components/control/Button'

import Accordion from './Accordion'

import s from './Carousel.module.css'
import { avatarClasses } from '@mui/material'

const Carousel = ({ page, data, gs = {} }) => {
  const { carousel } = data
  const transition = {
    duration: 0.5,
    ease: easingTransitions?.easeOutQuart,
  }

  const [isMobile, setIsMobile] = useState(false)
  const [[current, direction], setCurrent] = useState([0, 0])

  const [w_normal, w_active] = useMemo(() => {
    if (!isMobile) return ['16%', '36%']
    return ['36%', '58%']
  }, [isMobile])

  const [h_normal, h_active] = useMemo(() => {
    if (!isMobile) return ['14vw', '21vw']
    return ['32vw', '45vw']
  }, [isMobile])

  const [carouselList, setCarousel] = useState([
    { ...carousel[carousel.length - 3], index: 1 },
    { ...carousel[carousel.length - 2], index: 2 },
    { ...carousel[carousel.length - 1], index: 3 },
    { ...carousel[0], index: 4 },
    { ...carousel[1], index: 5 },
    { ...carousel[2], index: 6 },
    { ...carousel[3], index: 7 },
  ])

  const moveCarousel = async (direction) => {
    // 移动 图片
    const copy = [...carouselList]
    const last = carouselList.pop()
    const first = carouselList.shift()
    let target
    if (direction > 0) {
      copy.shift()
      const index = carousel.findIndex((item) => last?.name === item?.name)
      if (index < carousel?.length - 1) {
        target = carousel[index + 1]
      } else {
        target = carousel[0]
      }
      setCarousel([...copy, { ...target, index: last.index + 1 }])
    } else {
      copy.pop()
      const index = carousel.findIndex((item) => first?.name === item?.name)
      if (index > 0) {
        target = carousel[index - 1]
      } else {
        target = carousel[carousel?.length - 1]
      }
      setCarousel([{ ...target, index: first.index - 1 }, ...copy])
    }

    // 移动 文案
    const cur = current + direction
    if (cur < 0) return setCurrent([carousel?.length - 1, direction])
    if (cur >= carousel?.length - 1) return setCurrent([0, direction])
    return setCurrent([cur, direction])
  }

  const resizeWindow = throttle(() => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth <= 768) return setIsMobile(true)
    setIsMobile(false)
  }, 300)

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [resizeWindow])

  return (
    <div className={cn(s.root, gs?.root)}>
      <SectionTitle title={data?.title} gs={gs} />

      {data?.mode === 'carousel' && (
        <div className="layer">
          <div className={cn('content', s.carouselBox)}>
            <div className={s.nameBox}>
              <div className={s.nameContent}>
                <ul className={s.nameList}>
                  <AnimatePresence mode="popLayout" custom={direction}>
                    <motion.li
                      className={s.nameLi}
                      key={`name-${current}`}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={transition}
                      custom={direction}
                      variants={{
                        enter: (direction) => {
                          return {
                            x: direction > 0 ? '100%' : '-100%',
                          }
                        },
                        center: {
                          x: 0,
                        },
                        exit: (direction) => {
                          return {
                            x: direction < 0 ? '100%' : '-100%',
                          }
                        },
                      }}
                    >
                      {carousel[current]?.name}
                    </motion.li>
                  </AnimatePresence>
                </ul>
              </div>
            </div>
            <div className={s.carouselUlBox}>
              <motion.ul
                className={s.carouselUL}
                style={{
                  height: h_active,
                }}
              >
                <AnimatePresence mode="popLayout">
                  {carouselList?.map((item, i) => {
                    return (
                      <motion.li
                        layout
                        key={`${item?.name}-${item?.index}`}
                        className={s.carouselLI}
                        initial={{
                          opacity: 0.8,
                          height: h_normal,
                          width: w_normal,
                        }}
                        exit={{
                          opacity: 0.8,
                          height: h_normal,
                          width: w_normal,
                        }}
                        animate={i === 3 ? 'active' : 'normal'}
                        transition={transition}
                        variants={{
                          active: {
                            opacity: 1,
                            height: h_active,
                            width: w_active,
                          },
                          normal: {
                            opacity: 1,
                            height: h_normal,
                            width: w_normal,
                          },
                        }}
                      >
                        <div className={s.carouselContent}>
                          <Picture
                            source={`${item?.img}`}
                            alt=""
                            loading="eager"
                          />
                        </div>
                      </motion.li>
                    )
                  })}
                </AnimatePresence>
              </motion.ul>
            </div>
            <div className={s.describeBox}>
              <div className={s.describeContent}>
                <div className={s.describeList}>
                  <AnimatePresence mode="popLayout" custom={direction}>
                    <motion.p
                      className={s.describeLi}
                      key={`name-${current}`}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={transition}
                      custom={direction}
                      variants={{
                        enter: (direction) => {
                          return {
                            x: direction > 0 ? '100%' : '-100%',
                          }
                        },
                        center: {
                          x: 0,
                        },
                        exit: (direction) => {
                          return {
                            x: direction < 0 ? '100%' : '-100%',
                          }
                        },
                      }}
                    >
                      {carousel[current]?.describe}
                    </motion.p>
                  </AnimatePresence>
                </div>
                <button
                  className={cn(s.action, s.leftBtn)}
                  onClick={() => moveCarousel(1)}
                >
                  <span className="hidden">left</span>
                  <ArrowLongLeftIcon
                    width={20}
                    fill="#fff"
                    className={s.icon}
                  />
                </button>
                <button
                  className={cn(s.action, s.rightBtn)}
                  onClick={() => moveCarousel(-1)}
                >
                  <span className="hidden">right</span>
                  <ArrowLongRightIcon
                    width={20}
                    fill="#fff"
                    className={s.icon}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {data?.mode === 'accordion' && <Accordion data={carousel} gs={gs} />}
    </div>
  )
}

export default Carousel
